import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Pagination from '@mui/material/Pagination';


function Allocation() {

  const [isSelected, setIsSelected] = useState(false);
  const [allocation, setAllocation] = useState()
  const [id, setId] = useState()
  const [isVeiw, setIsVeiw] = useState(false)
  const [dob, setDob] = useState("");
  const [age, setAge] = useState('');
  const [mobile, setMobile] = useState()
  const [address, setAddress] = useState()
  const [area, setArea] = useState()
  const [pinCode, setPinCode] = useState()
  const [empType, setEmpType] = useState('Business')
  const [sourceType, setSourceType] = useState('Website')
  const [leadStatus, setLeadStatus] = useState('New')
  const [Intrest, setIntrest] = useState('')
  const [budget, setBudget] = useState()
  const [financeType, setFinanceType] = useState('Own')
  const [sitevisit, setSiteVisit] = useState('');
  const [later, setLater] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
    setValidated(false)
  };
  const [Validated, setValidated] = useState(false);





  const [open1, setOpen1] = useState(false)

  const formValidation = (event) => {
    event.preventDefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_primary()
    }

    setValidated(true);
  };

  const [al, setAl] = useState([])

  const leadDataGet = () => {
    axios.post('https://buildfastvapi.emedha.in/api/v1/getsaleexc').then((res) => {
      if (res.data.status === 200) {
        setAl(res.data.data)
        console.log(res.data.data);
      }
    })
  }

  React.useEffect(() => {
    leadDataGet()
    getlm()
  }, [])

  const [data, setData] = useState([])

  const getlm = () => {
    axios.get('https://buildfastvapi.emedha.in/api/v1/lead-data').then((res) => {

      setData(res.data.data)
      console.log(res.data.data);

    })
  }

  const handlesub = () => {
    axios.post('https://buildfastvapi.emedha.in/api/v1/updateSalesExecutive', { salesExecutive: salesexct, rowsToUpdate }).then((res) => {
      handleClose()
      // setviewData(id)
      setOpen(false)
      getlm()
      window.location.reload()
      Swal.fire({
        icon: 'success',
        title: "Successfull !",
        text: " Data added successfully !",
        confirmButtonColor: "rgb(126, 110, 228)",
        cancelButtonColor: "#d33",
        showCancelButton: false,
        confirmButtonText: "Ok"
      }).then((result) => {
        if (result.value) {
          return
        }
      })

    })
  }


  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {

      setRowsToUpdate(prevRows => [...prevRows, itemId]);

    } else {

      setRowsToUpdate(prevRows => prevRows.filter(id => id !== itemId));

    }
  };

  const [rowsToUpdate, setRowsToUpdate] = useState([])
  const [salesexct, setSalexct] = useState('')



  const deleteAlert = (id) => {
    handleClose2()
    Swal.fire({
      icon: 'warning',
      title: "Warning !",
      text: "Are you sure to delete this data",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes"
    })
  }

  const leadDataSearch = (text) => {
    axios.post('https://buildfastvapi.emedha.in/api/v1/search-lead', {
      text
    }).then((res) => {

      setData(res.data)

    })
  }

  const [page, setPage] = useState(5)
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e, val) => {
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = data.slice(indexOffpage, indexOflpage)

  return (
    <>
      <div>
        {
          !isVeiw && (
            <div className='leadManagementTable'>
              <Paper elevation={0} >

                <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                  <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                  <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                  <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                    <div>

                      <input onChange={(e) => leadDataSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                    </div>
                    <div>
                      <Button onClick={() => setOpen(true)} style={{ width: '10rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Allocate Leads</Button>
                    </div>
                  </div>
                  {/* <Navbar expand="lg" className="bg-body-tertiary">
              <Container fluid>
                <Navbar.Collapse id="navbarScroll">
                  <Row className='mb-3'>
                  <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>

                    <Nav.Link href="#action1">
                      <Form.Group as={Col} md="9" controlId="validationCustom01">
                        <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                        <Form.Control
                          type="text"
                          style={{ padding: '0.42rem', marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Date 
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Nav.Link>
                    <Nav.Link href="#action2"><Form.Group as={Col} md="9" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                      <Form.Control
                        type="text"
                        style={{ padding: '0.42rem', marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Date 
                      </Form.Control.Feedback>
                    </Form.Group></Nav.Link>
                    <Nav.Link href="#action1"><Form.Group as={Col} md="9" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                      <Form.Control
                        type="text"
                        style={{ padding: '0.42rem', marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Date 
                      </Form.Control.Feedback>
                    </Form.Group></Nav.Link>
                    <Nav.Link href="#action2"><Form.Group as={Col} md="9" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                      <Form.Control
                        type="text"
                        style={{ padding: '0.42rem', marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Date of Birth
                      </Form.Control.Feedback>
                    </Form.Group></Nav.Link>
                    <Nav.Link href="#action1"><Form.Group as={Col} md="9" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                      <Form.Control
                        type="text"
                        style={{ padding: '0.42rem', marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Date 
                      </Form.Control.Feedback>
                    </Form.Group></Nav.Link>
                    <Nav.Link href="#action2"><Form.Group as={Col} md="9" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Date</Typography>
                      <Form.Control
                        type="text"
                        style={{ padding: '0.42rem', marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Date 
                      </Form.Control.Feedback>
                    </Form.Group></Nav.Link>

                  </Nav>
                  </Row>
                </Navbar.Collapse>
              </Container>
            </Navbar> */}


                  <Table style={{ position: 'relative', top: '1.3rem' }}>
                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                      <TableRow>
                        <TableCell
                          align={"start"}
                          style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                        >
                          {/* <input type='checkbox'></input> */}
                        </TableCell>

                        <TableCell
                          align={"start"}
                          style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                        >
                          Name
                        </TableCell>
                        <TableCell

                          align={"left"}
                          style={{ top: 57, fontWeight: '600', }}
                        >
                          Mobile
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                        >
                          Email
                        </TableCell>
                        {/* <TableCell

                          align={"left"}
                          style={{ top: 21, fontWeight: '600', }}
                        >
                          Budget
                        </TableCell> */}
                        {/* <TableCell

                    align={"start"}
                    style={{ top: 57, fontWeight: '600', }}
                  >
                    Email
                  </TableCell> */}

                        {/* <TableCell

                    align={"start"}
                    style={{ top: 57, fontWeight: '600', }}
                  >
                    DOB
                  </TableCell> */}
                        {/* <TableCell

                    align={"left"}
                    style={{ top: 57, fontWeight: '600', }}
                  >
                    Age
                  </TableCell> */}


                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Area
                  </TableCell> */}
                        {/* <TableCell

                    align={"start"}
                    style={{ top: 57, fontWeight: '600', }}
                  >
                    Street
                  </TableCell> */}
                        {/* <TableCell

                    align={"left"}
                    style={{ top: 57, fontWeight: '600', }}
                  >
                    Adress
                  </TableCell> */}
                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Pincode
                  </TableCell> */}

                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Employment Type
                  </TableCell> */}
                        {/* <TableCell

                          align={"left"}
                          style={{ top: 21, fontWeight: '600', }}
                        >
                          Finance
                        </TableCell> */}

                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Intrest
                  </TableCell> */}
                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Site Visit
                  </TableCell> */}
                        {/* <TableCell

                    align={"left"}
                    style={{ top: 21, fontWeight: '600', }}
                  >
                    Later
                  </TableCell> */}
                        <TableCell
                          align={"start"}
                          style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                        >
                          Sales Executive
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        currentpost.map((item => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>
                            <TableCell align="start" style={{ fontSize: 14, fontWeight: '600' }}>
                              <input type='checkbox'
                                onChange={(e) => handleCheckboxChange(e, item.Id)}
                              ></input>
                            </TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
                            {/* <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.budget}</TableCell> */}
                            {/* <TableCell align={"start"}
                    style={{ top: 57 }}>
                    <div style={{ border: `3px solid ${Col}`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%' }}>
                      <FaUserCircle style={{ color: 'rgb(126, 110, 228)', fontSize: 24 }} />
                    </div>
                  </TableCell> */}


                            {/* <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.dob}</TableCell>
                  <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.age}</TableCell> */}

                            {/* <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.area}</TableCell>
                  <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.street}</TableCell>
                  <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.address}

                  </TableCell>

                  <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.pinCode}</TableCell>
                  <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}>{item.empType}</TableCell> */}
                            {/* <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.financeType}</TableCell> */}


                            {/* <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}></TableCell> */}
                            {/* <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}></TableCell> */}
                            {/* <TableCell align={"start"}
                    style={{ top: 57, fontSize: 12 }}></TableCell> */}
                            <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>

                            <TableCell align={"start"}
                              style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                              <div style={{ display: 'flex' }} >
                                {/* <Button onClick={() => allocateAlert()} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Allocate</Button> */}
                                <div>
                                  <IconButton
                                    id="fade-button"
                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open2 ? 'true' : undefined}
                                    onClick={(e) => {
                                      handleClick(e.currentTarget)

                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open2}
                                    onClose={handleClose2}
                                    TransitionComponent={Fade}
                                  >
                                    {/* <MenuItem style={{ color: 'gray' }} onClick={() => {

                                      handleClose2()
                                      setIsVeiw(true)
                                    }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem> */}
                                    {
                                      (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                        <MenuItem style={{ color: 'gray' }} onClick={() => {
                                          //  viewData(id)
                                          handleClose2()
                                          setOpen(true)

                                        }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                      )
                                    }

                                    {/* <MenuItem style={{ color: 'gray' }} onClick={() => (id)}><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem> */}
                                  </Menu>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )))
                      }
                    </TableBody>
                  </Table>


                  {
                    data.length === 0 && (
                      <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                        <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                        <Typography>0 customers</Typography>
                      </Box>

                    )
                  }
                </TableContainer>
              </Paper>
              <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
              </div>
            </div>
          )
        }
        {
          isVeiw && (
            <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative', }} >
              <FaWindowClose onClick={() => {
                setIsVeiw(false)
                leadDataGet()

              }} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} ></FaWindowClose>
              <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }} ><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></Typography>
                <Typography>Lead customers information details</Typography>
              </div>
              <br />
              <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>

                <div className='card' style={{ paddingTop: 43, border: '1px solid rgb(126, 110, 228)', borderRadius: '0.3rem', padding: 11, flexDirection: 'row', justifyContent: 'space-between' }} >
                  <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                    {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Name:</span> {name}</Typography>
                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>MObile:</span> {mobile}</Typography>
                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Email:</span> {email}</Typography>
                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Budget:</span> {budget}</Typography> */}
                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Sales Executive:</span> {salesexct}</Typography>
                  </div>

                </div>
                {/* <div style={{ marginTop: 15 }}>
                  <center>
                    <Button onClick={() => {
                     
                    }} style={{ backgroundColor: 'green', color: 'white', fontSize: 14, height: '2.3rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Excel</Button>

                    <Button onClick={() => {

                     

                    }} style={{ backgroundColor: 'red', color: 'white', fontSize: 14, height: '2.3rem', position: 'relative', top: '0.4rem', left: '-0.2rem', marginLeft: 3 }} >Pdf</Button>
                  </center>
                </div> */}
              </div>
            </div>
          )
        }

        <div>
          <Dialog
            style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
            maxWidth='xl'
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >

            <DialogContent>
              <div style={{ width: "37rem" }} >
                <Form noValidate validated={Validated} onSubmit={formValidation} >
                  <div className='mb-4 mt-1'>
                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Lead</Typography>
                  </div>
                  <Row className="mb-3">

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
                      <Form.Select
                        required
                        onChange={(e) => setSalexct(e.target.value)}
                        type="text"



                        style={{ padding: '0.42rem', marginBottom: 14 }}


                      >

                        <option>Sales Executive</option>
                        {
                          al.map((item => (
                            <option>{item.UserName}</option>
                          )))
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Sales Executive
                      </Form.Control.Feedback>
                    </Form.Group>



                  </Row>
                  <div className='d-flex'>
                    <div>
                      <Button onClick={() => handlesub()} type='button' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                    </div>
                    <div>
                      <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div>
          <Dialog
            style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
            maxWidth='xl'
            open={open1}
            onClose={() => setOpen1(!open1)}
            aria-labelledby="responsive-dialog-title"
          >

            <DialogContent>
              <div style={{ width: "37rem" }} >
                <Form noValidate validated={Validated} onSubmit={formValidation} >
                  <div className='mb-4 mt-1'>
                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Lead</Typography>
                  </div>
                  <Row className="mb-3">

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
                      <Form.Select
                        required
                        onChange={(e) => setSalexct(e.target.value)}
                        type="text"

                        value={salesexct}

                        style={{ padding: '0.42rem', marginBottom: 14 }}


                      >

                        <option>Sales Executive</option>
                        {
                          al.map((item => (
                            <option>{item.UserName}</option>
                          )))
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Sales Executive
                      </Form.Control.Feedback>
                    </Form.Group>



                  </Row>
                  <div className='d-flex'>
                    <div>
                      <Button onClick={() => handlesub()} type='button' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                    </div>
                    <div>
                      <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

      </div>
    </>
  )
}

export default Allocation;
