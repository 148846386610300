import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  FaCheck,
  FaEdit,
  FaEye,
  FaMobileAlt,
  FaPhone,
  FaTrash,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import FilterListIcon from "@mui/icons-material/FilterList";
import { BsFillArchiveFill } from "react-icons/bs";
// import MailBody from "../MailBody/MailBody";
// import Message from "../Message/Message";
// import TicketSearch from "../TicketSearch/TicketSearch";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";





console.log(sessionStorage.getItem("project_name"));

const PrName = sessionStorage.getItem("ProjectName");
console.log(PrName);

const CrmHead = () => {
  const [tickets, setTickets] = useState([]);

  React.useEffect(() => {
    axios
      .get("https://buildfastapi.kollagroup.in/internal/api/v1/api/tickets")
      .then((response) => {
        const crmTickets = response.data.filter(
          (ticket) => ticket.receiver === "CRM Head"
        );
        setTickets(crmTickets);
      })
      .catch((error) => console.error("Error fetching tickets:", error));
  }, []);

  const handleReply = (ticketNumber) => {
    console.log("Reply to ticket:", ticketNumber);
  };

  const handleCancel = (ticketNumber) => {
    console.log("Cancel ticket:", ticketNumber);
  };

  const handleSubmit = (ticketNumber) => {
    console.log("Submit ticket:", ticketNumber);
  };

  const handleTicketClosed = (ticketNumber) => {
    setTickets(
      tickets.filter((ticket) => ticket.ticket_number !== ticketNumber)
    );
  };

 
const [projectName, setProjectName] = useState("");
const [projectNameVilla,setProjectNameVilla]=useState("")


const [fetchedVillaData, setFetchedVillaData] = useState([]); // Initialize with an empty array


// 


const fetchDataVilla = async () => {
  try {
    const response = await fetch(`https://buildfastvapi.emedha.in/create-customer/api/v1/villa-billing-data?projectName=${projectNameVilla}`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    setFetchedVillaData(data); // Update state with fetched data
    console.log(fetchedVillaData)
  } catch (error) {
    console.error('Error fetching data:', error);
    // Handle error (e.g., show error message)
  }
};

useEffect(()=>{
  fetchDataVilla()
},[projectNameVilla])


const [ttowerno, setTowardsTowerNumber] = useState("");

const [responset, setResponset] = useState([]);

const get_floor = () => {
  axios
    .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
    .then((res) => {
      if (res.data.status === 200) {
        setResponset(res.data.data);
        console.log(res.data.data);
      }
    });
};

React.useEffect(() => {

  get_floor();

}, []);



const [response4, setResponse4] = React.useState([]);
const [selectedFloor, setSelectedFloor] = useState('');

const handleFloorChange =async (e) => {
  const floor = e.target.value;
  setSelectedFloor(floor);

}

console.log(response4)

// https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor

const get_floors = (ttowerno) => {
  axios
    .post(
      "https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor",
      {
        value1: ttowerno,
      }
    )
    .then((res) => {
      setResponse4(res.data);
      // setTower(value1);
      //select_floor();
    });
};

// https://buildfastapi.kollagroup.in/create-customer/api/v1/bill/tower/data?ttowerno=${ttowerno}

const [fetchedData, setFetchedData] = useState([]); // Initialize with an empty array

const fetchData = async () => {
  try {
    const response = await fetch(`https://buildfastapi.kollagroup.in/create-customer/api/v1/bill/tower/data?ttowerno=${ttowerno}&floor=${selectedFloor}`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    setFetchedData(data); // Update state with fetched data
    console.log(fetchedData)
  } catch (error) {
    console.error('Error fetching data:', error);
    // Handle error (e.g., show error message)
  }
};

useEffect(() => {
  get_floors(ttowerno)
  if (ttowerno  && selectedFloor) {
    fetchData();
  }
}, [ttowerno,selectedFloor]); // Dependencies for useEffect

useEffect(() => {
  console.log(fetchedData); // Log fetchedData whenever it changes
}, [fetchedData]); // Run this effect whenever fetchedData changes

const billingCount=fetchedData.length
console.log(billingCount)

const [popupOpen, setPopupOpen] = useState(false);
const [selectedItem, setSelectedItem] = useState(null); // State to store selected item for popup

const handleTotalValueClick = (item) => {
  setSelectedItem(item)
  setPopupOpen(true);
};

const handleClosePopup = () => {
  setSelectedItem(null); // Close popup by resetting selected item
  setPopupOpen(false); // Close the popup
};



const [filterInput, setFilterInput] = useState('');
const [filteredData, setFilteredData] = useState(fetchedData);

React.useEffect(() => {
  setFilteredData(fetchedData);

}, [fetchedData]);


const handleFilterInputChange = (event) => {
  const value = event.target.value.toLowerCase();
  setFilterInput(value);
  const filtered = fetchedData.filter(
    (item) =>
      item.ucin.toLowerCase().includes(value) ||
    
      item.flatNo.toLowerCase().includes(value)
  );
  setFilteredData(filtered);
// Reset page when applying filters
};

console.log(selectedItem);

const countFilledInvoices = (data) => {
  let invoiceCount = 0; // Counter for invoices in main object
  let paymentInvoiceCount = 0; // Counter for invoices in payments array

  // Loop through each object in the fetchedData array
  data.forEach((item) => {
    // Check invoices in the main object
    for (let i = 1; i <= 10; i++) {
      const invoiceKey = `invoice${i}`;
      if (item[invoiceKey] !== "0" && item[invoiceKey] !== null) {
        invoiceCount++;
      }
    }

    // Check invoices in payments array if it exists and is an array
    if (Array.isArray(item.payments)) {
      item.payments.forEach((payment) => {
        if (payment.invoice !== "0" && payment.invoice !== null) {
          paymentInvoiceCount++;
        }
      });
    }
  });

  return { invoiceCount, paymentInvoiceCount };
};
// Usage example
const { invoiceCount, paymentInvoiceCount } = countFilledInvoices(fetchedData);
console.log("Number of invoices with filled values in main object:", invoiceCount);
console.log("Number of invoices with filled values in payments:", paymentInvoiceCount);

const Dues=invoiceCount-paymentInvoiceCount


const countFilledInvoicesVilla = (data) => {
  let invoiceCountVilla = 0; // Counter for invoices in main object
  let paymentInvoiceCountVilla = 0; // Counter for invoices in payments array

  // Loop through each object in the fetchedData array
  data.forEach((item) => {
    // Check invoices in the main object
    for (let i = 1; i <= 10; i++) {
      const invoiceKey = `invoice${i}`;
      if (item[invoiceKey] !== "0" && item[invoiceKey] !== null) {
        invoiceCountVilla++;
      }
    }

    // Check invoices in payments array if it exists and is an array
    if (Array.isArray(item.payments)) {
      item.payments.forEach((payment) => {
        if (payment.invoice !== "0" && payment.invoice !== null) {
          paymentInvoiceCountVilla++;
        }
      });
    }
  });

  return { invoiceCountVilla, paymentInvoiceCountVilla };
};
// Usage example
const { invoiceCountVilla, paymentInvoiceCountVilla } = countFilledInvoicesVilla(fetchedVillaData);
console.log("Number of invoices with filled values in main object:", invoiceCountVilla);
console.log("Number of invoices with filled values in payments:", paymentInvoiceCountVilla);

const DuesVilla=invoiceCountVilla-paymentInvoiceCountVilla

  return (
    <>
      <div className="main-cards">

        {sessionStorage.getItem('project_type')!=="Villa" &&
        <>
        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{ backgroundColor: "#8a0404", color: "white" }}
        >

          <div className="card-inner text-center">

            <h3>Billing Count</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{invoiceCount}</h6>
         
          </div>

        </div>

        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{
            backgroundColor: "#d2be79",
            color: "white",
            height: "135px",
          }}
        >
          <div className="card-inner ">
            <h3>Payment Dues</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{Dues}</h6>
          </div>
        </div>

        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{ backgroundColor: "#d35050", color: "white" }}
        >
          <div className="card-inner text-center">
            <h3>Milestones</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{paymentInvoiceCount}</h6>
          </div>
        </div>
        </>
}

{sessionStorage.getItem('project_type')==="Villa" &&
        <>
        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{ backgroundColor: "#8a0404", color: "white" }}
        >

          <div className="card-inner text-center">

            <h3>Billing Count</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{invoiceCountVilla}</h6>
         
          </div>

        </div>

        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{
            backgroundColor: "#d2be79",
            color: "white",
            height: "135px",
          }}
        >
          <div className="card-inner ">
            <h3>Payment Dues</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{DuesVilla}</h6>
          </div>
        </div>

        <div
          className="card-box d-flex flex-column justify-content-center"
          style={{ backgroundColor: "#d35050", color: "white" }}
        >
          <div className="card-inner text-center">
            <h3>Milestones</h3>
            <BsFillArchiveFill className="card-icon" />
            <h6>{paymentInvoiceCountVilla}</h6>
          </div>
        </div>
        </>
}



      </div>
      <div className='d-flex flex-row align-items-center p-2'>
        <div>
          <input
            value={filterInput}
            onChange={handleFilterInputChange}
            style={{
              height: '2.3rem',
              color: 'gray',
              borderRadius: '0.3rem',
              outline: 'none',
              width: '16rem',
              border: '0.7px solid lightgray',
            }}
            placeholder="Search...."
          />
        </div>

        {sessionStorage.getItem('project_type')!=="Villa" &&
        <>
          <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        ProjectName
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setProjectName(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Project</option>
                        <option>{PrName}</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01" >
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Tower{" "}
                      </Typography>
                      <Form.Select
                        required
                        value={ttowerno}
                        onChange={(e) => setTowardsTowerNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Tower </option>
                        {responset
                          .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
                          .map((item) => (
                            <option>{item.Towers}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Tower Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Floor
                    </Typography>
                    <Form.Select
                      required
                    onChange={handleFloorChange}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Floor</option>
                      {[
                        ...new Set(response4.map((data) => data.floor.trim())),
                      ].map((floor, index) => (
                        <option key={index}>{floor}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group>
                  </>}

                  <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        ProjectName
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setProjectNameVilla(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Project</option>
                        <option>{sessionStorage.getItem('project_name')}</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    </div>

                   {sessionStorage.getItem('project_type')==="Villa"?(<TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Project Name</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Villa No</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Customer Name</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>UCIN</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Total Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchedVillaData.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.ProjectName}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.flatNo}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.customerName}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.ucin}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 ,color: 'blue'}} onClick={() => handleTotalValueClick(row)}>{row.totalValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ):(<TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Floor</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Flat No</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Customer Name</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>UCIN</TableCell>
            <TableCell  align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Total Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.floor}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.flatNo}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.customerName}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.ucin}</TableCell>
              <TableCell align="start" style={{ fontSize: 12, minWidth: 160 ,color: 'blue'}} onClick={() => handleTotalValueClick(row)}>{row.totalValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )} 
    {/* Your existing code to render the dialog */}
    {selectedItem && (
        <Dialog
          open={popupOpen}
          onClose={handleClosePopup}
          PaperProps={{
            style: {
              maxWidth: '90%', // Adjust as needed
              minWidth: '600px', // Adjust as needed
            },
          }}
        >
          <DialogTitle style={{ color: 'blue' }}>Installments and Balances</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Invoice</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Installment</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Date</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Paid Amount</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Balance Amount</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Status</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Approval Date</TableCell>
                    <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: '600' }}>Transaction ID</TableCell>
                    {/* Add more headers as needed */}
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(selectedItem).map(key => {
            if (key.startsWith('invoice') && selectedItem[key] !== '0' && selectedItem[key] !== null) {
              const index = key.replace('invoice', '');
              const invoiceNumber = selectedItem[key];
              const installment = selectedItem['installment' + index];
              const TotalBillAmount=selectedItem['gstTotal'+index]
              const balanceKey='balance'+index
              const balanceAmount = selectedItem[balanceKey] ? Math.round(parseFloat(selectedItem[balanceKey])) : 0;
              const date = selectedItem['date' + index];

              // Check if payment for this invoice exists in the payments array
              const payment = selectedItem.payments.find(p => p.invoice === invoiceNumber);

              // If payment exists, use its details; otherwise, default to zeros
              const paidAmount = payment ? payment.paidAmount : '0';
              const status = payment ? payment.status : '0';
              const approvalDate = payment ? new Date(payment.approvalDate).toLocaleDateString() : '0';
              const transactionId = payment ? payment.transactionId : '0';

              return (
                <TableRow key={invoiceNumber}>
                  <TableCell align="start">{invoiceNumber}</TableCell>
                  <TableCell align="start">{TotalBillAmount}</TableCell>
                  <TableCell align="start">{date}</TableCell>
                  <TableCell align="start">{paidAmount}</TableCell>
                  <TableCell align="start">{balanceAmount}</TableCell>
                  <TableCell align="start">{status}</TableCell>
                  <TableCell align="start">{approvalDate}</TableCell>
                  <TableCell align="start">{transactionId}</TableCell>
                </TableRow>
              );
            }
            return null;
          })}
                
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}


      {/* <div>
        <h1
          style={{ color: "blue", textAlign: "center", marginBottom: "25px" }}
        >
          Internal Communication
        </h1>
        <MailBody />
        <h2 style={{ color: "black" }}>Tickets</h2>
        <ul>
          {tickets.map((ticket) => (
            <Message
              key={ticket.id}
              ticket={ticket}
              onReply={handleReply}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onTicketClosed={handleTicketClosed}
            />
          ))}
        </ul>
        <TicketSearch />
      </div> */}
    </>
  );
};

export default CrmHead;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Modal from "@mui/material/Modal";
// import { Button, TextField } from "@mui/material";
// import MailBody from "../MailBody/MailBody";
// import Message from "../Message/Message";

// const CrmHead = () => {
//   const [tickets, setTickets] = useState([]);
//   const [showReplyModal, setShowReplyModal] = useState(false);
//   const [replyMessage, setReplyMessage] = useState("");
//   const [replyTicketNumber, setReplyTicketNumber] = useState(null);

//   const fetchTickets = () => {
//     axios
//       .get("http://localhost:3003/api/tickets")
//       .then((response) => {
//         const crmTickets = response.data.filter(
//           (ticket) => ticket.receiver === "CRM Head"
//         );
//         setTickets(crmTickets);
//       })
//       .catch((error) => console.error("Error fetching tickets:", error));
//   };

//   useEffect(() => {
//     fetchTickets();
//   }, []);

//   const handleReply = (ticketNumber) => {
//     setShowReplyModal(true);
//     setReplyTicketNumber(ticketNumber);
//   };

//   const handleReplySubmit = () => {
//     axios
//       .post(`http://localhost:3003/api/tickets/reply/${replyTicketNumber}`, { replyContent: replyMessage })
//       .then((response) => {
//         console.log("Reply submitted:", response.data);
//         setShowReplyModal(false);
//         setReplyMessage("");
//         setReplyTicketNumber(null);
//         fetchTickets(); // Fetch tickets again to update the list with the reply
//       })
//       .catch((error) => console.error("Error submitting reply:", error));
//   };

//   const handleCancel = (ticketNumber) => {
//     console.log("Cancel ticket:", ticketNumber);
//   };

//   const handleSubmit = (ticketNumber) => {
//     console.log("Submit ticket:", ticketNumber);
//   };

//   const handleTicketClosed = (ticketNumber) => {
//     setTickets(tickets.filter(ticket => ticket.ticket_number !== ticketNumber));
//   };

//   return (
//     <>
//       <div>
//         <h1 style={{ color: "blue", textAlign: "center", marginBottom: "25px" }}>
//           Internal Communication
//         </h1>
//         <MailBody />
//         <h2 style={{ color: "black" }}>Tickets</h2>
//         <ul>
//           {tickets.map((ticket) => (
//             <Message
//               key={ticket.id}
//               ticket={ticket}
//               onReply={handleReply}
//               onCancel={handleCancel}
//               onSubmit={handleSubmit}
//               onTicketClosed={handleTicketClosed}
//             />
//           ))}
//         </ul>

//         <Modal open={showReplyModal} onClose={() => setShowReplyModal(false)}>
//           <div
//             style={{
//               padding: 20,
//               backgroundColor: "white",
//               margin: "auto",
//               width: "50%",
//               top: "50%",
//               transform: "translateY(-50%)",
//               position: "absolute",
//             }}
//           >
//             <h2>Reply to Ticket</h2>
//             <TextField
//               fullWidth
//               label="Reply"
//               value={replyMessage}
//               onChange={(e) => setReplyMessage(e.target.value)}
//               multiline
//               rows={4}
//               variant="outlined"
//               style={{ marginBottom: 20 }}
//             />
//             <Button variant="contained" color="primary" onClick={handleReplySubmit}>
//               Submit Reply
//             </Button>
//           </div>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default CrmHead;




// {Object.keys(selectedItem).map(key => {
//   if (key.startsWith('invoice') && selectedItem[key] !== '0' && selectedItem[key] !== null) {
//     const index = key.replace('invoice', '');
//     const invoiceNumber = selectedItem[key];
//     const installment = selectedItem['installment' + index];
//     const date = selectedItem['date' + index];

//     // Check if payment for this invoice exists
//     const paymentExists = selectedItem.payment && selectedItem.payment.invoice === invoiceNumber;

//     // If payment exists, use its details; otherwise, default to zeros
//     const paidAmount = paymentExists ? selectedItem.payment.paidAmount : '0';
//     const status = paymentExists ? selectedItem.payment.status : '0';
//     const approvalDate = paymentExists ? selectedItem.payment.approvalDate : '0';
//     const transactionId = paymentExists ? selectedItem.payment.transactionId : '0';

//     return (
//       <TableRow key={key}>
//         <TableCell align="start">{invoiceNumber}</TableCell>
//         <TableCell align="start">{installment}</TableCell>
//         <TableCell align="start">{date}</TableCell>
//         <TableCell align="start">{paidAmount}</TableCell>
//         <TableCell align="start">{status}</TableCell>
//         <TableCell align="start">{approvalDate}</TableCell>
//         <TableCell align="start">{transactionId}</TableCell>
//       </TableRow>
//     );
//   }
//   return null;
// })}