import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaEdit, FaRupeeSign, FaTrash, FaUserCircle } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';


export default function Other() {

    const [Facility_Management, setFacility_Management] = useState()
    const [Documentation, setDocumentation] = useState()
    const [Insurance, setInsurance] = useState()
    const [ProjectId, setProjectId] = useState()
    const [ProjectName, setProjectName] = useState()
    const [id, setId] = useState()


    const add_other = () => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/add-other", {
            Facility_Management, Documentation, Insurance, ProjectId, ProjectName
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_other()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [response, setResponse] = useState([])

    const get_other = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-other").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_other()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_other()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/delete-other', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_other()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = (Id) => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                otherDataEdit(id)
            }
        })
    }

    const otherDataEdit = (id) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/other-edit', {
            Facility_Management, Documentation, Insurance, ProjectId, ProjectName, id
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    get_other()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    const [open1, setOpen1] = useState(false)

    const viewData = () => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/view-other', {
            id
        }).then((res) => {
            setFacility_Management(res.data.Facility_Management)
            setDocumentation(res.data.Documentation)
            setInsurance(res.data.Insurance)
            setProjectName(res.data.ProjectName)
            setOpen1(true)
        })
    }


    const searchOther = (text) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/search-other', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }


    return (
        <div>


            <div className='leadManagementTable'>
                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e)=>searchOther(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            <div>
                                <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Other</Button>
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Project Name
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Facility Management
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Documentation
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Insurance
                                    </TableCell>

                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {response
                                    .map((row) => {

                                        let color = ''
                                        let widths = ''

                                        if (row.lead === 'Not Customer') {
                                            color = 'red'
                                            widths = false
                                        }

                                        if (row.lead === 'Customer') {
                                            color = 'green'
                                            widths = true
                                        }


                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.ProjectName}</TableCell>
                                               <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Facility_Management} /-</button>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Documentation} /-</button>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Insurance} /-</button>
                                                </TableCell>
                                            
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                    <div style={{ display: 'flex' }} >
                                                        {/* <Button onClick={() => deleteAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button> */}
                                                        <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.id)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >

                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                    handleClose2()
                                                                    viewData(id)

                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>

                                                                <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {
                            response.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>0 customers</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Other</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setProjectName(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Facility Management</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setFacility_Management(e.target.value)}

                                            type="number"
                                            pattern='(/^[0-9\b\+\-\(\)]+$/);'
                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Facility_Management
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Documentation</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setDocumentation(e.target.value)}

                                            type="number"
                                            pattern='(/^[0-9\b\+\-\(\)]+$/);'

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Documentation
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Insurance</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setInsurance(e.target.value)}
                                            type="number"
                                            pattern='(/^[0-9\b\+\-\(\)]+$/);'

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid Insurance
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                     
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={()=>setOpen1(false)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Others</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setProjectName(e.target.value)}
                                            type="text"
                                            value={ProjectName}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Facility Management</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setFacility_Management(e.target.value)}

                                            type="text"
                                            value={Facility_Management}
                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Facility_Management
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Documentation</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setDocumentation(e.target.value)}

                                            type="text"
                                            value={Documentation}
                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Documentation
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Insurance</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setInsurance(e.target.value)}
                                            type="text"
                                            value={Insurance}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid Insurance
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                     
                                </Row>
                                <div className='d-flex'>
                                <div>
                                    <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                </div>
                                    <div>
                                        <Button onClick={() => setOpen1(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}