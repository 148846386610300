// import React, { useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import axios from 'axios';

// const CustomerDetailsModal = ({ ucin, show, handleClose }) => {
//   const [customerDetails, setCustomerDetails] = useState(null);

//   React.useEffect(() => {
//     if (ucin) {
//       axios.get(`https://buildfastapi.kollagroup.in/create-customer/api/v1/customer/${ucin}`)
//         .then(response => setCustomerDetails(response.data))
//         .catch(error => console.error('Error fetching customer details:', error));
//     }
//   }, [ucin]);

//   if (!customerDetails) return null;

//   console.log(customerDetails)


  // const modalStyle = {
  //   zIndex: 10000// Adjust the zIndex value as needed
  // };

//   return (
//     <Modal show={show} onHide={handleClose} centered style={modalStyle}>
//       <Modal.Header closeButton>
//         <Modal.Title>Customer Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p><strong>Customer Name:</strong> {customerDetails.customerName}</p>
//         <p><strong>Email:</strong> {customerDetails.email}</p>
//         <p><strong>Mobile Number:</strong> {customerDetails.mobileNumber}</p>
//         <p><strong>Aadhar No:</strong> {customerDetails.aadharNo}</p>
//         <p><strong>PAN No:</strong> {customerDetails.panNo}</p>
//         <p><strong>Address:</strong> {customerDetails.address}</p>
//         <p><strong>Flat No:</strong> {customerDetails.flatNo}</p>
//         <p><strong>Project Name:</strong> {customerDetails.projectName}</p>
//         <p><strong>UCINO:</strong> {customerDetails.ucin}</p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default CustomerDetailsModal;


import React, { useState, useEffect } from 'react';
import { Modal, Button, ModalHeader ,ModalFooter, ModalBody} from 'react-bootstrap';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper

} from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { Interests } from '@mui/icons-material';


const CustomerDetailsModal = ({ ucin, show, handleClose }) => {
  const [customerData, setCustomerData] = useState(null);
 
  const [loading, setLoading] = useState(false);


  const modalStyle = {
    width: '100%', // Adjust the width as needed
   // Optional: Set a maximum width for responsiveness
    margin: 'auto' ,
 
    zIndex:10000// Center the modal horizontally
  };

  const [billingData, setBillingData] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [interestData, setInterestData] = useState([]);


  // https://buildfastvapi.emedha.in/create-customer/api/v1/customer/${ucin}

  useEffect(() => {
    if (ucin) {
      axios.get(`https://buildfastvapi.emedha.in/create-customer/api/v1/customer/${ucin}`)
        .then(response => {
          const { customer, billing, payments,interest } = response.data;
          console.log('Fetched Data:', customer, billing, payments,interest);
          setCustomerData(customer);
          setBillingData(billing);
          setPaymentData(payments);
          setInterestData(interest) // Ensure paymentData is set correctly
        })
        .catch(error => {
          console.error('Error fetching customer data:', error);
        });
    }
  }, [ucin]);

  useEffect(() => {
    console.log('billingData:', billingData);
    console.log('paymentData:', paymentData);
    console.log('interestData:', interestData);
  }, [billingData, paymentData,interestData]);





  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle null or undefined dates
  
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  



const renderPaymentInvoiceCells = () => {
  let paymentInvoiceCells = [];

  // Ensure paymentData and interestData are arrays before proceeding
  if (!Array.isArray(paymentData) || paymentData.length === 0) {
    console.log('paymentData is empty or not an array:', paymentData);
  }
  if (!Array.isArray(interestData) || interestData.length === 0) {
    console.log('interestData is empty or not an array:', interestData);
  }

  // Loop through all possible invoices in billingData (invoice1 to invoice10)
  for (let i = 1; i <= 10; i++) {
    const invoiceKey = `invoice${i}`;
    const balanceKey = `balance${i}`;
    const dateKey = `date${i}`;
    const gstTotalKey = `gstTotal${i}`;

    // Check if billingData has invoice details for current invoice number
    if (billingData && billingData[invoiceKey] && billingData[invoiceKey] !== '0') {
      // Determine the bill date to display (use date from billingData)
      const billDate = billingData[dateKey] ? new Date(billingData[dateKey]).toLocaleDateString() : 'N/A';

      // Determine the balance to display (use balance from billingData)
      const balance = billingData[balanceKey] ? Math.round(parseFloat(billingData[balanceKey])) : '0';

      // Find all payment entries corresponding to the current invoice in paymentData
      const paymentEntries = paymentData.filter(item => item.invoice === billingData[invoiceKey]);

      // Find all interest entries corresponding to the current invoice in interestData
      const interestEntries = interestData.filter(item => item.invoice === billingData[invoiceKey]);

      // Always add a row for the invoice, regardless of payments or interests
      paymentInvoiceCells.push(
        <TableRow key={`${invoiceKey}`}>
          <TableCell>{billingData[invoiceKey]}</TableCell>
          <TableCell>{billingData[gstTotalKey]}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? paymentEntries[0].paidAmount : 'N/A'}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? paymentEntries[0].status : 'N/A'}</TableCell>
          <TableCell>{formatDate(billDate)}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? formatDate(paymentEntries[0].checkdate) : 'N/A'}</TableCell>
          <TableCell>{balance}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].interestAmount : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].TotalAmount : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? formatDate(interestEntries[0].interestDate) : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].daysCount : 'N/A'}</TableCell>
        </TableRow>
      );
    }
  }

  return paymentInvoiceCells;
};


  
  const hasBillingData = billingData && Object.keys(billingData).some(key => key.startsWith('installment') && billingData[key] !== '0');

  // Render the table only if there's matching billing data
  if (hasBillingData) {
    return (
      <Modal show={show} onHide={handleClose} centered size="xl" style={modalStyle}>
      <Modal.Header closeButton>
        <Modal.Title>Customer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Invoice No</strong></TableCell>
                <TableCell><strong>Invoice Amount</strong></TableCell>
                <TableCell><strong>Paid Amount</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Bill Date</strong></TableCell>
                <TableCell><strong>Paid Date</strong></TableCell>
                <TableCell><strong>Balance</strong></TableCell>
                <TableCell><strong>Interest Amount</strong></TableCell>
                <TableCell><strong>Total Amount</strong></TableCell>
                <TableCell><strong>Interest Date</strong></TableCell>
                <TableCell><strong>Days Count</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderPaymentInvoiceCells()}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    );
  } else {
    return null; // Render nothing if there's no matching billing data
  }
};




export default CustomerDetailsModal;
