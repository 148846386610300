import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { FaCaretLeft, FaRupeeSign } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Row from 'react-bootstrap/esm/Row';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Product from './Product';
import Uom from './Uom';

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'auto',
}));

export default function Createpurchasemaster() {

   
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const[product, setProduct] = React.useState('')
    const [uom, setUom] = React.useState('')

    const productData = (a) => {
        console.log(a);
        setProduct(a)
    }

    const uomData = (a) => {
        console.log(a);
        setUom(a)
    }
    
    const createpurchase = () => {
        const pl = Array.prototype.map.call(product, function (item) { return item.attachment }).join(",");
        const ol = Array.prototype.map.call(uom, function (item) { return item.attachment }).join(",");
        axios.post("https://buildfastvapi.emedha.in/pmaster/api/v1/add-productsmaster", {
            ProductName:pl,UOM:ol
        }).then((res) => {
            if (res.data.status === 200) {
                // setOpen(false)
                window.location.reload()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }
  

   


    return (
        <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12 }>
                    <Item className='card' style={{ height: "96.7vh", position: 'relative' }} sx={{ backgroundColor: 'rgb(126, 110, 228)', justifyContent: 'center', overflow: 'auto' }}>

                        <Item className='card col-6 mx-auto' >
                            <Typography style={{ fontSize: 21, color: 'rgb(126, 110, 228)' }} ><FaCaretLeft onClick={() => window.location.reload()} style={{ marginTop: '-0.3rem', cursor: 'pointer', color: 'rgb(126, 110, 228)' }} /> Add PurchaseMaster</Typography>
                            <Typography style={{ color: 'gray', fontSize: 19, marginBottom:10,marginTop:20 }}>Add Product </Typography>
                            <Product productData={productData}/>
                            <Typography style={{ color: 'gray', fontSize: 19, marginBottom:10,marginTop:10  }}>Add UOM </Typography>
                            <Uom uomData={uomData}/>
                            <Button  style={{ backgroundColor: 'rgb(126, 110, 228)', color: 'white', width: '11rem', marginTop: 7 }} onClick={createpurchase}>create purchase</Button>
                        </Item>
                    </Item>
                </Grid>
            </Grid>
            
        </Box>
    );
}