
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography } from '@mui/material';
import { jsPDF } from 'jspdf';
import { Button, Col, Form, Dropdown, Row, Container } from "react-bootstrap";
import 'jspdf-autotable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ProjectReport = () => {
  const [ttowerno, setTowardsTowerNumber] = useState('');
  const [responset, setResponset] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [prname, setPrName] = useState('');

  const [projectName, setProjectName] = useState([]);
  const [vPrname,setVPrname]=useState('');
  const [projectNameVilla, setProjectNameVilla] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([
    { id: 'VillaNo', label: 'Villa No', width: 160, isVisible: true },
    { id: 'villasize', label: 'Villa Size', width: 170, isVisible: true },
    // { id: 'status', label: 'Status', width: 0, isVisible: true },
    { id: 'villasqyard', label: 'Villa Sqyard', width: 0, isVisible: true },
    { id: 'facing', label: 'Facing', width: 0, isVisible: true },
    // { id: 'cornor', label: 'Corner', width: 0, isVisible: true },
    { id: 'totalValue', label: 'Total Value', width: 0, isVisible: true },
    { id: 'facingAmount', label: 'Facing Amount', width: 0, isVisible: true },
    // { id: 'cornerAmount', label: 'Corner Amount', width: 0, isVisible: true },
    { id: 'basePrice', label: 'Base Price', width: 0, isVisible: true },
    { id: 'advMaitanceAmt', label: 'Advance Maintenance Amount', width: 0, isVisible: true },
    { id: 'carpousAmt', label: 'Car Parking Amount', width: 0, isVisible: true },
    { id: 'cautionNonRefAmt', label: 'Caution Non Refundable Amount', width: 0, isVisible: true },
    { id: 'cautionRefAmt', label: 'Caution Refundable Amount', width: 0, isVisible: true },
    { id: 'clubHouseAmt', label: 'Clubhouse Amount', width: 0, isVisible: true },
    { id: 'waterElectricityAmt', label: 'Water Electricity Amount', width: 0, isVisible: true },
    // { id: 'floorRiseAmount', label: 'Floor Rise Amount', width: 0, isVisible: true },
  ]);

  const [viewMode, setViewMode] = useState('Full View');
  
  const [selectedColumnIds, setSelectedColumnIds] = useState(selectedColumns.filter(col => col.isVisible).map(col => col.id));

  useEffect(() => {
    const updatedColumns = selectedColumns.map(col => ({
      ...col,
      isVisible: viewMode === 'Full View' ? true : selectedColumnIds.includes(col.id)
    }));
    setSelectedColumns(updatedColumns);
  }, [viewMode, selectedColumnIds]);

  
  
  // useEffect(() => {
  //   getFloor();
  //   getProjectNames();
  // }, []);

  useEffect(()=>{
    getVillaProjectNames()
  },[])

  useEffect(() => {
    if (viewMode === 'Full View') {
      setSelectedColumns(prevColumns => prevColumns.map(col => ({ ...col, isVisible: true })));
    }
  }, [viewMode]);

  // const getFloor = () => {
  //   axios
  //     .post('https://buildfastapi.kollagroup.in/project/api/v1/towers-getall')
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setResponset(res.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching floor data:', error);
  //     });
  // };

  // const getProjectNames = () => {
  //   axios
  //     .get('https://buildfastapi.kollagroup.in/project/api/v1/get-basic')
  //     .then((res) => {
  //       setProjectName(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };


  const getVillaProjectNames = () => {
    axios
      .get('https://buildfastvapi.emedha.in/project/api/v1/get-basic')
      .then((res) => {
        setProjectNameVilla(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [villaPlots,setVillaPlots]=useState([])
   console.log(villaPlots)

  const getVillaPlots=()=>{
    axios.get('https://buildfastvapi.emedha.in/project/api/v1/get-villa-plots',{
      params:{projectName:vPrname}
    }).then((res)=>{
      setVillaPlots(res.data.data)
    })
  }

  useEffect(()=>{
    getVillaPlots()
  },[vPrname])


  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://buildfastapi.kollagroup.in/api/v1/api/project-details?ProjectName=${prname}&TowerNumber=${ttowerno}`
  //     );
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }
  //     const data = await response.json();
  //     setFetchedData(data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   if (ttowerno && prname) {
  //     fetchData();
  //   }
  // }, [ttowerno, prname]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const columns = selectedColumns
      .filter(col => col.isVisible)
      .map((col) => ({
        header: col.label,
        dataKey: col.id,
        width: col.width,
      }));

    const rows = villaPlots.map((row) => {
      const filteredRow = {};
      selectedColumns.forEach((col) => {
        if (col.isVisible) {
          filteredRow[col.id] = row[col.id];
        }
      });
      return filteredRow;
    });

    doc.autoTable({
      columns,
      body: rows,
      margin: { top: 10, left: 10 },
      columnStyles: { 0: { cellWidth: 'auto' } },
    });

    doc.save('tablepdf.pdf');
  };

  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prevColumns =>
      prevColumns.map(col =>
        col.id === columnId ? { ...col, isVisible: !col.isVisible } : col
      )
    );
  };

  const handleColumnSelection = (colId) => {
    setSelectedColumnIds(prev =>
      prev.includes(colId) ? prev.filter(id => id !== colId) : [...prev, colId]
    );
  };


  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const projectNames = sessionStorage.getItem("ProjectName");

    // Filter projectNamesss based on userType and ProjectName
    const filtered = projectNameVilla.filter(project => {
      if (userType === "Admin") {
        return project.ProjectName === projectNames;
      }
      return true; // Show all projects for non-admins
    });

    setFilteredProjects(filtered);
  }, [projectNameVilla]);


  return (
    <>
      <div className="card mt-2" style={{ border: 'none', paddingLeft: 15, paddingRight: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div>
          <label style={{ fontSize: '13px', color: 'gray' }}>Search Filter</label>
          <br />
          <input
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '20rem', border: '0.7px solid lightgray' }}
            placeholder="Search Details.."
          />
        </div>
        <div>
          <div className="d-flex flex-row align-items-center p-2 mt-3" style={{width:'280px'}}>

          <Form.Group as={Col} md="12" controlId="validationCustom01" className="mr-2 p-2">
              <Typography style={{ color: 'gray', fontSize: 13 }}>ProjectName</Typography>
              <Form.Select
                required
                onChange={(e) => setVPrname(e.target.value)}
                value={vPrname}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option>Select Project</option>
                {filteredProjects.map((item) => (
                  <option key={item.ProjectName} value={item.ProjectName}>
                    {item.ProjectName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Project Name</Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group as={Col} md="5" controlId="validationCustom01" className="mr-2 p-2">
              <Typography style={{ color: 'gray', fontSize: 13 }}>ProjectName</Typography>
              <Form.Select
                required
                onChange={(e) => setPrName(e.target.value)}
                value={prname}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option>Select Project</option>
                {filteredProjects.map((item) => (
                  <option key={item.ProjectName} value={item.ProjectName}>
                    {item.ProjectName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Project Name</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" controlId="validationCustom01">
              <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
              <Form.Select
                required
                value={ttowerno}
                onChange={(e) => setTowardsTowerNumber(e.target.value)}
                type="text"
                style={{ padding: "0.42rem", marginBottom: 14 }}
              >
                <option>Select Tower</option>
                {responset
                  .filter((item) => item.Towers.startsWith(prname))
                  .map((item, index) => (
                    <option key={index}>{item.Towers}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Tower Number</Form.Control.Feedback>
            </Form.Group>*/}
            <Form.Group as={Col} md="8" controlId="viewMode">
              <Typography style={{ color: 'gray', fontSize: 13 }}>View Mode</Typography>
              <Form.Select
                onChange={(e) => setViewMode(e.target.value)}
                value={viewMode}
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option value="Full View">Full View</option>
                <option value="Customized View">Customized View</option>
              </Form.Select>
            </Form.Group> 
          </div>
        </div>
      </div>

    
      <div className="d-flex flex-row align-items-center">
        {viewMode === 'Customized View' && (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Select Columns
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '800px', overflowX: 'auto' }}>
              <Container>
                <Row>
                  {selectedColumns.map((col, index) => (
                    <Col key={col.id} xs={6} md={4} lg={3}>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${col.id}`}
                        label={col.label}
                        checked={selectedColumnIds.includes(col.id)}
                        onChange={() => handleColumnSelection(col.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ position: "relative", top: "1.3rem" }} id="table-to-xls">
          <TableHead sx={{ borderTop: "1px solid lightgray" }}>
            <TableRow>
              {selectedColumns.map((col, index) => (
                col.isVisible && (
                  <TableCell key={index} align="start" style={{ minWidth: col.width, fontSize: 14, fontWeight: "600" }}>
                    {col.label}
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {villaPlots
              .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {selectedColumns.map((col, idx) => (
                    col.isVisible && (
                      <TableCell key={idx} align="start" style={{ fontSize: 12, minWidth: col.width }}>
                        {row[col.id]}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={villaPlots
          .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
          .length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className='d-flex flex-row align-items-center'>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Excel"
          style={{ display: 'none' }} // Hide default button
        />

        <Button
          variant="contained"
          color="danger"
          size="large"
          style={{ marginLeft: '2rem', height: '50px' }}
          onClick={downloadPDF}
        >
          PDF
        </Button>
      </div>
    </>
  );
};

export default ProjectReport;
