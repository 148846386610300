import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaDotCircle, FaEdit, FaRupeeSign, FaTrash, FaUserCircle } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
// import Units from './Units';
import Pagination from 'react-bootstrap/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';


export default function Villatypicalunits() {


    const [Blocks, setBlocks] = useState()
    const [ProjectName, setProjectName] = useState("")
    const [id, setId] = useState()
    const [bval, setBval] = useState('')
    const [units, setUnits] = useState([]);
    const [Tower, setTower] = useState("")
    const [upload, setUpload] = useState()

    const [blog, setBlog] = useState('');

    const handleChange = (e) => {
        const newBlocks = parseInt(e.target.value);
        if (!isNaN(newBlocks) && newBlocks !== '' && Number(newBlocks) >= 0 && Number(newBlocks) <= 99) {
            setBlog(newBlocks)
        }
        else {
            setBlog('')
        }
    };

    const unitData = (a) => {
        console.log(a);
        setUnits(a)
    }

    const renderInputs = () => {
        const input = parseInt(blog)
        return Array.from({ length: input }, (_, index) => (
            <>
                <Typography style={{ color: 'gray', fontSize: 13 }}>{index}</Typography>
                <Form.Control
                    key={index}
                    required
                    type="text"
                    style={{ padding: '0.42rem', marginBottom: 14 }}
                    onChange={(e) => setBval(e.target.value)}
                />

            </>
        ))
    };


    const [inputs, setInputs] = useState([]);
    const addBlock = (value, index) => {

        let updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const add_blocks = () => {
        // const block = Array.prototype.map.call(units, function (item) { return item.attachment }).join(",");
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/add-blocks", {
            ProjectName, Blocks: units
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        }).catch((error) => {
            console.error(error);
            setOpen(false);
    
            Swal.fire({
                icon: 'error',
                title: "Error!",
                text: "Blocks already exist  in the selected Project. please try again.",
                confirmButtonColor: "#d33",
                cancelButtonColor: "rgb(126, 110, 228)",
                showCancelButton: false,
                confirmButtonText: "Ok"
            });
        });
    }

    const [response, setResponse] = useState([])

    const get_common = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-blocks").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_unit()
        get_common()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_blocks()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/typicaldel', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_unit()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                blockEdit(id)
            }
        })
    }

    const blockEdit = (id) => {
       
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/typical-villa-update', {
            facing:efac,unitsize:eunitsize, slno:id, pname:villaProjectName, villaSqyard:villaSqyard, villaPlotSize:villaPlotSize
        }).then((res) => {
            if (res.status === 200) {
                get_unit()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                
            }
        })
    }

    const [open1, setOpen1] = useState(false)
    const [efac, setEfac] = useState('')
    const [ecorn, setEcorn] = useState('')
    const [eunitsize, setEunitsize] = useState('')
    const [villaSqyard,setunitsqyard]=useState('')
    const [villaPlotSize,setplotsize]=useState('')
    const [villaProjectName,setProjectNamee]=useState('')

    console.log(eunitsize)
    console.log(villaSqyard)
    console.log(villaPlotSize)
    console.log(villaProjectName)

    const viewData = (id) => {
        setOpen1(true)
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/get-typical-villa-units-id', { id })
        .then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                const data = res.data.data[0]; // Access the first object
                setEfac(data.facing);
                setEcorn(data.cornor);
                setEunitsize(data.unitsize);
                setunitsqyard(data.sqyard);
                setplotsize(data.plotsize);
                setProjectNamee(data.pName);
            } else {
                console.error("No data found");
            }
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
        });
    
    }


    React.useEffect(() => {
        console.log("Unitsize: ", eunitsize);
        console.log("Square Yard: ", villaSqyard);
        console.log("Plot Size: ", villaPlotSize);
        console.log("Project Name: ", villaProjectName);
    }, [eunitsize, villaSqyard, villaPlotSize, villaProjectName]);
    


    const searchCommon = (text) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/search-blocks', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectName = searchParams.get('id');


    const [unitdata, setUnitdata] = useState([]);

    const get_unit = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-typical-villa-units", {
            params: { projectName }
        })
        .then((res) => {
            setUnitdata(res.data.data);
            console.log("Response data:", res.data);
        })
        .catch((error) => {
            console.error("Error fetching units:", error);
        });
    };

    return (
        <div>


            <div className='leadManagementTable'>
            <ArrowBackIcon onClick={()=>history.back()}/>
            <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Villa Typical units</Typography>

                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            {
                                (sessionStorage.getItem('Add') === '1' || sessionStorage.getItem('superAdmin')) && (

                                    <div>
                                        {/* <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Block</Button> */}
                                    </div>
                                )
                            }
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        UnitSize 
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Unit Sqyard
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Facing
                                    </TableCell>
                                   
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                      PlotSize
                                    </TableCell>
                                    
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {unitdata
                                    .map((row) => {

                                        let color = ''
                                        let widths = ''

                                        if (row.lead === 'Not Customer') {
                                            color = 'red'
                                            widths = false
                                        }

                                        if (row.lead === 'Customer') {
                                            color = 'green'
                                            widths = true
                                        }


                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align={"start"}
                                                    style={{ top: 57 }}>
                                                    {row.unitsize}
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.sqyard}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.facing}</TableCell>

                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaDotCircle style={{ marginRight: 3, color: 'white', marginTop: '-0.05rem', fontSize: 9 }} />{row.plotsize}</button>
                                                </TableCell>

                                               
                                               
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                    <div style={{ display: 'flex' }} >
                                                        {/* {
                                                            (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                <Button onClick={() => deleteAlert(row.Id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button>
                                                            )
                                                        } */}
                                                        <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.slno)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >
                                                                {
                                                                    (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                        <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                            handleClose2()
                                                                            viewData(id)

                                                                        }}><FaEdit style={{ marginRight: 4 }} /> Edits</MenuItem>
                                                                    )
                                                                }

                                                                {
                                                                    (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                        <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                    )
                                                                }

                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {
                            unitdata.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>No Data Found</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
            </div>


            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(!open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Typical Villas</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating blocks details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Facing</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => {
                                                setEfac(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                            
                                            value={efac}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Facing Name</option>
                                            <option>East</option>
                                            <option>West</option>
                                            <option>North</option>
                                            <option>South</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Size</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => {
                                                        setEunitsize(e.target.value)
                                                        console.log(e.target.value)
                                                    }}

                                                    type="text"
                                                    value={eunitsize}
                                                    style={{ padding: '0.42rem', marginBottom: 14, }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Blocks
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Sqyard</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => {
                                                        setunitsqyard(e.target.value)
                                                        console.log(e.target.value)
                                                    }}

                                                    type="text"
                                                    value={villaSqyard}
                                                    style={{ padding: '0.42rem', marginBottom: 14, }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Blocks
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit PlotSize</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => {
                                                        setplotsize(e.target.value)
                                                        console.log(e.target.value)
                                                    }}

                                                    type="text"
                                                    value={villaPlotSize}
                                                    style={{ padding: '0.42rem', marginBottom: 14, }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Blocks
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                   
                                    
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen1(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        
        </div>
    );
}