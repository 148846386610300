import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from 'react-bootstrap/Modal';

const steps = ['Step 1', 'Step 2'];

export default function Costadd() {


    const [Blocks, setBlocks] = useState()
    const [ProjectName, setProjectName] = useState("")
    const [id, setId] = useState()
    const [bval, setBval] = useState('')
    const [units, setUnits] = useState([]);
    const [Tower, setTower] = useState("")
    const [upload, setUpload] = useState()

    const [value, setValue] = React.useState('1');

    const handleChangev = (event, newValue) => {
        setValue(newValue);
    };

    const [blog, setBlog] = useState('');

    const handleChange = (e) => {
        const newBlocks = parseInt(e.target.value);
        if (!isNaN(newBlocks) && newBlocks !== '' && Number(newBlocks) >= 0 && Number(newBlocks) <= 99) {
            setBlog(newBlocks)
        }
        else {
            setBlog('')
        }
    };

    const unitData = (a) => {
        console.log(a);
        setUnits(a)
    }

    const renderInputs = () => {
        const input = parseInt(blog)
        return Array.from({ length: input }, (_, index) => (
            <>
                <Typography style={{ color: 'gray', fontSize: 13 }}>{index}</Typography>
                <Form.Control
                    key={index}
                    required
                    type="text"
                    style={{ padding: '0.42rem', marginBottom: 14 }}
                    onChange={(e) => setBval(e.target.value)}
                />

            </>
        ))
    };


    const [inputs, setInputs] = useState([]);
    const addBlock = (value, index) => {

        let updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const add_blocks = () => {
        // const block = Array.prototype.map.call(units, function (item) { return item.attachment }).join(",");
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/add-blocks", {
            ProjectName, Blocks: units
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        }).catch((error) => {
            console.error(error);
            setOpen(false);
    
            Swal.fire({
                icon: 'error',
                title: "Error!",
                text: "Blocks already exist  in the selected Project. please try again.",
                confirmButtonColor: "#d33",
                cancelButtonColor: "rgb(126, 110, 228)",
                showCancelButton: false,
                confirmButtonText: "Ok"
            });
        });
    }

    const [response, setResponse] = useState([])

    const get_common = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-blocks").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_unit()
        get_common()
        get_unitfacing()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            // onepricesubmit()
            handleSubmit()
        }

        setValidated(true);
    };
    const [Validated2, setValidated2] = useState(false);

    const formValidation2 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            // handleSubmit()
            handletwo()
        }

        setValidated2(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/typicaldel', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_unit()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                blockEdit(id)
            }
        })
    }

    const blockEdit = (id) => {
       
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/typical-edit', {
            facing:efac,cornor:ecorn,unitsize:eunitsize, slno:id
        }).then((res) => {
            if (res.status === 200) {
                get_unit()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                
            }
        })
    }

    const [open1, setOpen1] = useState(false)
    const [openpd, setOpenpd] = useState(false)
    const [efac, setEfac] = useState('')
    const [ecorn, setEcorn] = useState('')
    const [eunitsize, setEunitsize] = useState('')

    const viewData = (id) => {
        setOpen1(true)
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/typicalbyid', {
            id
        }).then((res) => {
            // console.log(res.data);
            setEfac(res.data.facing)
            setEcorn(res.data.cornor)
            setEunitsize(res.data.unitsize)
          
        })
    }


    const searchCommon = (text) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/search-blocks', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const pnval = searchParams.get('id');
    const pnval = 'luxor'


    const [unitdata, setUnitdata] = useState([])
    const get_unit = (text) => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/get-pwunit",{text}).then((res) => {
            setUnitdata(res.data.data)
            console.log("asddasd",res.data);

        })
    }

    const [datacamt, setDatacamt] = useState('')
    const [facgedata, setFacgedata] = useState('')
    const [facgwdata, setFacgwdata] = useState('')
    const [facgndata, setFacgndata] = useState('')
    const [facgsdata, setFacgsdata] = useState('')

    const get_unitfacing = () => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/projwiseamounts",{text:pnval}).then((res) => {
            console.log("camt",res.data[0].corner_amt);
            setDatacamt(res.data[0].corner_amt)
            setFacgedata(res.data[0].east_amt)
            setFacgndata(res.data[0].north_amt)
            setFacgwdata(res.data[0].west_amt)
            setFacgsdata(res.data[0].south_amt)

        })
    }

    const [responsep, setResponsep] = useState([])

    const get_basicp = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponsep(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basicp()
        getpriceface()
    }, [])

    const [response3, setResponse3] = useState([])

    const get_floor = (text) => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/get-pwfloor",{text}).then((res) => {
            if (res.data.status === 200) {
                setResponse3(res.data.data)
                console.log('floorcount',res.data.data.map((a) => a.selectType));
                console.log('Maximum last digit:', Math.max(...res.data.data.map(a => parseInt(a.selectType.slice(-1)))));
                const maxLastDigit = Math.max(...res.data.data.map(a => {
                    const lastDigit = parseInt(a.selectType.slice(-1));
                    return isNaN(lastDigit) ? 0 : lastDigit; 
                }));
                setFcnt(maxLastDigit);
                setMaxLastDigit(maxLastDigit);
                setFloorData(Array.from({ length: maxLastDigit }, () => ({ floorAmount: '' })));
            }
        })
    }



    const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleFinish  = () => {
    setActiveStep(0);
    setOpen1(false)
    setValidated(false)
    setValidated2(false)
    window.location.reload()
  };

//   const handleFloorRiseAmountChange = (index, blockIndex, amount) => {
//     // Log the data
//     console.log(`Floor ${index + 1}, ${response3[index].selectType.split(',')[blockIndex].trim()}: ${amount}`);
//     // You can assign the amount to the particular floor here
// };

const [fcnt, setFcnt] = useState(0)

const initialFloorRiseAmounts = React.useMemo(() => Array(fcnt).fill({}), [fcnt]);

const [floorRiseAmounts, setFloorRiseAmounts] = useState(initialFloorRiseAmounts);

    const handleFloorRiseAmountChange = (index, blockIndex, amount) => {
        // Update floor rise amount for the specified floor and block
        const updatedFloorRiseAmounts = [...floorRiseAmounts];
        updatedFloorRiseAmounts[index] = {
            ...updatedFloorRiseAmounts[index],
            [blockIndex]: amount
        };
        setFloorRiseAmounts(updatedFloorRiseAmounts);
        console.log('framt', updatedFloorRiseAmounts);
    };

    const [prjname, setPrjname] = useState('')

    const [ocpark, setOcpark] = useState('')
    const [tcpark, setTcpark] = useState('')
    const [ecpark, setEpark] = useState('')
    const [pvamt, setPvamt] = useState('')
    const [camt, setCamt] = useState('')
    const [sname, setSname] = useState('')
    const [scheamt, setScheamt] = useState('')
    const [btntwo, setBtntwo] = useState(true)
    const [btnone, setBtnone] = useState(true)

    const handletwo = () => {
        console.log({ocpark, tcpark, ecpark, pvamt, camt, sname, scheamt});
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/pricetwodata',{onecarpark:ocpark, twocarpark:tcpark, threecarpark:ecpark, parkview_amt:pvamt, corner_amt:camt, scheme_amt:scheamt, scheme_name:sname, projName:prjname})
        .then((res) => {
            if(res.data.status === 200){
                setBtntwo(false)
                console.log(res.data);
            }
        })
    }


    const [fdata, setFdata] = useState()
    const [baseprice, setBasicprice] = useState('')
    const [eamt, setEamt] = useState('')
    const [wamt, setWamt] = useState('')
    const [namt, setNamt] = useState('')
    const [samt, setSamt] = useState('')
    // const [prjname, setPrjname] = useState('')



    const handleSubmit = () => {
        
        // const floorsData = response3.map((data, index) => {
        //     const floorRiseAmount = floorRiseAmounts[index];
        //     const floorData = data.selectType.split(',').map((block, blockIndex) => ({
        //         floorName: block.trim(),
        //         floorRiseAmount: floorRiseAmount[blockIndex] || 0 
        //     }));
        //     return floorData;
        // });


        // const floorNames = [...Array(fcnt)].map((_, index) => `Floor ${index + 1}`);
        // const floorsData = floorNames.map((floorName, index) => ({
        //     floorName: floorName,
        //     floorRiseAmount: (floorRiseAmounts[index] && floorRiseAmounts[index][floorName]) || 0
        // }));

        // console.log(floorsData);
        // setFdata(floorsData)

        // console.log('datadone',floorData);

        const formattedFloorData = floorData.map((item, index) => ({
            floorName: `floor${index + 1}`,
            floorRiseAmount: item.floorAmount || 0
        }));

        console.log("Formatted Floor Data:", formattedFloorData);

        
        console.log({baseprice,eamt,wamt,namt,samt,prjname,fdata:formattedFloorData});

        axios.post('https://buildfastvapi.emedha.in/project/api/v1/pricdataso',{baseprice:baseprice, east_amt:eamt, west_amt:wamt, north_amt:namt, south_amt:samt, projName:prjname, floorrisedata:formattedFloorData, onecarpark:ocpark, twocarpark:tcpark, threecarpark:ecpark, parkview_amt:pvamt, corner_amt:camt, scheme_amt:scheamt, scheme_name:sname })
        .then((res) => {
            if(res.data.status == 200){

                console.log(res.data);
                setBtnone(false)
            }
        })

    };
    
    const [scheme, setScheme] = useState('no');

    const handleSchemeChange = (e) => {
        setScheme(e.target.value);
    };

    const [pricfacedata, setPricfacedata] = useState([])

    const getpriceface = () => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/getallfacing')
        .then(res => {
            setPricfacedata(res.data.data)
        })
    }


    const [showm, setShowm] = useState(false);

  const handleClosem = () => setShowm(false);
  const handleShowm = () => setShowm(true);

  const [pdprojname, setPdprojname] = useState('')
  const [pdeamt, setPdeamt] = useState('')
  const [pdnamt, setPdnamt] = useState('')
  const [pdsamt, setPdsamt] = useState('')
  const [pdwamt, setPdwamt] = useState('')
  const [pdcor, setPdcor] = useState('')
  const [pdo, setPdo] = useState('')
  const [pdtwo, setPdtwo] = useState('')
  const [pdthre, setPdthre] = useState('')
  const [pdpv, setPdpv] = useState('')
  const [pdbp, setPdbp] = useState('')
  const [pdsname, setPdsname] = useState('')
  const [pdscheamt, setPdschamt] = useState('')

  const viewprice = (id) => {
    axios.post('https://buildfastvapi.emedha.in/project/api/v1/projwiseviewfacing',{id})
    .then((res) => {
        console.log("view",res.data);
        setPdprojname(res.data[0].projName)
        setPdeamt(res.data[0].east_amt)
        setPdnamt(res.data[0].north_amt)
        setPdsamt(res.data[0].south_amt)
        setPdwamt(res.data[0].west_amt)
        setPdcor(res.data[0].corner_amt)
        setPdo(res.data[0].onecarpark)
        setPdtwo(res.data[0].twocarpark)
        setPdthre(res.data[0].threecarpark)
        setPdpv(res.data[0].parkview_amt)
        setPdbp(res.data[0].baseprice)
        setPdsname(res.data[0].scheme_name)
        setPdschamt(res.data[0].scheme_amt)
    })
  }

  const [floorrisecharge, setFloorrisecharge] = useState([])

  const getchgrefloor = (text) => {
      axios.post('https://buildfastvapi.emedha.in/project/api/v1/getfloorisecharge',{text})
      .then(res => {
        console.log(res.data);
        setFloorrisecharge(res.data.data)
      })
  }

  const [maxLastDigit, setMaxLastDigit] = useState(0);
  const [floorData, setFloorData] = useState([]);

  const handleFloorAmountChange = (index, event) => {
    const { value } = event.target;
    setFloorData(prevFloorData => {
        const updatedFloorData = [...prevFloorData];
        updatedFloorData[index] = { ...updatedFloorData[index], floorAmount: value };
        return updatedFloorData;
    });
};

    return (
        <div>


            <div className='leadManagementTable'>
            <ArrowBackIcon onClick={()=>history.back()}/>
           

                <Paper elevation={0} >
                <Box sx={{ width: '100%', typography: 'body1' }}>

                    <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'rgb(86, 63, 146)' }} >
                        <TabList onChange={handleChangev} aria-label="lab API tabs example" indicatorColor="secondary" >
                            <Tab label="Pricing" value="1" style={{ color: 'rgb(86, 63, 146)' }} />
                            <Tab label="UnitwisePrice" value="2" style={{ color: 'rgb(86, 63, 146)' }} />
                        </TabList>
                    </Box>
                    <TabPanel value="1" >

                    <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Pricing Details</Typography>

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            <div>
                                
                           
                            </div>
                            {
                                (sessionStorage.getItem('Add') === '1' || sessionStorage.getItem('superAdmin')) && (

                                    <div>
                                        <Button onClick={() => setOpen1(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add </Button>
                                    </div>
                                )
                            }

                           
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                    Project Name 
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Base Price
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        
                                        Scheme Name
                                    </TableCell>
                                   
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Scheme Amount

                                    </TableCell>

                                    
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {pricfacedata
                                    .map((row) => {
                                        return(

                                       
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.Id}>
                                                
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.projName}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.baseprice}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.scheme_name}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}> {row.scheme_amt}</TableCell>

                                                    <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                                                    <VisibilityIcon onClick={() => {
                                                        handleShowm()
                                                        viewprice(row.Id)
                                                        setOpenpd(true)
                                                        getchgrefloor(row.projName)
                                                    }}/>
                                                    </TableCell>

                  
                                               
                                            </TableRow>
                                        )
                                        
                                    })}
                               
                            </TableBody>
                        </Table>
                        {
                            pricfacedata.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>No Data Found</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                    </TabPanel>
                    <TabPanel value="2" >

                    <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Unit wise Price Details</Typography>

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            <div>
                                
                           
                            </div>
                            {/* {
                                (sessionStorage.getItem('Add') === '1' || sessionStorage.getItem('superAdmin')) && (

                                    <div>
                                        <Button onClick={() => setOpen1(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add </Button>
                                    </div>
                                )
                            } */}

                            <div style={{width:'20rem'}}>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                                    <Form.Select
                                                       
                                                        onChange={(e) => {
                                                            get_unit(e.target.value)
                                                        }}

                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>Select Project Name</option>
                                                        {
                                                            responsep.map((data) => {
                                                                return (
                                                                    <option>{data.ProjectName}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Unit Measurements
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                    unitname 
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        unitsize
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        
                                        Cornor -- Amount
                                    </TableCell>
                                   
                                    <TableCell
                                        align={"center"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Facing -- amount

                                    </TableCell>

                                    
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Total value (INR)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {unitdata
                                    .map((row) => {
                                        return(

                                       
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.unit_name}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.unitsizeNum}</TableCell>
                                                <TableCell align={"center"}
                                                    style={{ top: 57, fontSize: 12 }}>{`${row.cornor} -- ${row.cornerAmount}`}</TableCell>
                                                <TableCell align={"center"}
                                                    style={{ top: 57, fontSize: 12 }}> { `${row.Facing} -- ${row.facingAmount}`
                                                    }</TableCell>

                                                    <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                                                        {row.totalvalue}
                                                    </TableCell>

                  
                                               
                                            </TableRow>
                                        )
                                        
                                    })}
                               
                            </TableBody>
                        </Table>
                        {
                            unitdata.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>No Data Found</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                    </TabPanel>
                    </TabContext>
                    </Box>
                </Paper>
            </div>


            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(!open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Pricing</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating blocks details will receive a privacy audit.</Typography> */}
                                </div>

                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        //   if (isStepOptional(index)) {
                                        //     labelProps.optional = (
                                        //       <Typography variant="caption">Optional</Typography>
                                        //     );
                                        //   }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                        })}
                                    </Stepper>
                                    {activeStep === 0 ? (
                                        <div>
      
                                        <Form noValidate validated={Validated} onSubmit={formValidation} >
                                            <div className='mb-4 mt-1'>
                                                {/* <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}> Project</Typography> */}
                                            </div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Base Price</Typography>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder='Enter your Base Price'
                                                        value={baseprice}
                                                        onChange={(e) => setBasicprice(e.target.value)}
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter a valid project name.
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <div >
                                                <Typography style={{ color: 'black', fontSize: 14,marginBottom:3,fontWeight:'bold'}}>Facing Charges</Typography>
                                                <Row className="mb-3">

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>East</Typography>
                                                    <Form.Control
                                                        required
                                                        placeholder='Enter East Amount'
                                                        type="text"
                                                        value={eamt}
                                                        onChange={(e) => setEamt(e.target.value)}

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>West</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setWamt(e.target.value)}
                                                        value={wamt}
                                                        type="text"
                                                        placeholder='Enter West Amount'
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}


                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter valid location
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>North</Typography>
                                                    <Form.Control
                                                        required
                                                        placeholder='Enter North Amount'
                                                        type="text"
                                                        value={namt}
                                                        onChange={(e) => setNamt(e.target.value)}

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>South</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setSamt(e.target.value)}
                                                        type="text"
                                                        value={samt}
                                                        placeholder='Enter South Amount'
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}


                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter valid location
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                </Row>
                                                
                                                </div>
                                                
                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'black', fontSize: 14,marginBottom:3,fontWeight:'bold' }}>Park View Charges</Typography>
                                                    <Form.Control
                                                        required
                                                        placeholder='Enter  Amount'
                                                        type="text"
                                                        value={pvamt}
                                                        onChange={(e) => setPvamt(e.target.value)}

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'black', fontSize: 14,marginBottom:3,fontWeight:'bold' }}>Corner  Charges</Typography>
                                                    <Form.Control
                                                        required
                                                        placeholder='Enter  Amount'
                                                        type="text"
                                                        value={camt}
                                                        onChange={(e) => setCamt(e.target.value)}

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Row className="mb-3">


                                               
                                              
                                               
                                            </Row>

                                                <Typography style={{ color: 'black', fontSize: 14,marginBottom:3,fontWeight:'bold' }}>Floor Rise Charges*</Typography>



                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                                    <Form.Select
                                                        required
                                                        type='text'
                                                        value={prjname}
                                                       
                                                        onChange={(e) => {
                                                            get_floor(e.target.value)
                                                            setPrjname(e.target.value)
                                                        }}

                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>Select Project Name</option>
                                                        {
                                                            responsep.map((data) => {
                                                                return (
                                                                    <option>{data.ProjectName}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Unit Measurements
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                                {/* <div>
                                                {floorRiseAmounts.map((_, index) => (
                                                <div key={index}>
                                                    {[...Array(fcnt)].map((_, blockIndex) => {
                                                        return (
                                                            <div key={blockIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={`Floor${blockIndex + 1}`}
                                                                    disabled
                                                                    style={{ marginRight: '20px', width: '120px' }}
                                                                />
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter floor rise amount"
                                                                    onChange={(e) => handleFloorRiseAmountChange(index, blockIndex, e.target.value)}
                                                                    style={{ width: '200px' }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                                </div> */}
                                                <div>
                                                {/* {Array.from({ length: maxLastDigit }, (_, index) => (
                                                    <div key={index}>
                                                        <label htmlFor={`floor${index + 1}`}>Floor name: </label>
                                                        <input type="text" id={`floor${index + 1}`} defaultValue={`floor${index + 1}`} disabled />
                                                        <label htmlFor={`floorAmount${index + 1}`}>Floor Amount:</label>
                                                        <input
                                                            type="text"
                                                            id={`floorAmount${index + 1}`}
                                                            name={`floorAmount${index + 1}`}
                                                            value={floorData[index]?.floorAmount || ''}  
                                                            onChange={(event) => handleFloorAmountChange(index, event)}
                                                        />
                                                    </div>
                                                ))} */}

                                        {floorData.map((floor, index) => (
                                                        <div key={index}  style={{ display: 'flex', alignItems: 'center', justifyContent:'space-evenly',marginBottom:4 }}>
                                                            <Form.Group controlId={`floor${index + 1}`}>
                                                                <Form.Control
                                                                    type="text"
                                                                    defaultValue={`floor${index + 1}`}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId={`floorAmount${index + 1}`}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder='floorrise Amount'
                                                                    name={`floorAmount${index + 1}`}
                                                                    value={floorData[index]?.floorAmount || ''}
                                                                    onChange={(event) => handleFloorAmountChange(index, event)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    ))}
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid block name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Typography style={{ color: 'black', fontSize: 14,marginBottom:3,fontWeight:'bold'}}>Car Parking Charges </Typography>
                                                <Row className="mb-3">

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>One Cark Park</Typography>
                                                    <Form.Control
                                                        required
                                                        placeholder='Enter One Cark Park Amount'
                                                        value={ocpark}
                                                        onChange={(e) => setOcpark(e.target.value)}
                                                        type="text"

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Two Cark Park</Typography>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={tcpark}
                                                        onChange={(e) => setTcpark(e.target.value)}
                                                        placeholder='Enter Two Cark Park Amount'
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}


                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter valid location
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Three Cark Park</Typography>
                                                    <Form.Control
                                                        required
                                                        value={ecpark}
                                                        placeholder='Enter Three Cark Park Amount'
                                                        type="text"
                                                        onChange={(e) => setEpark(e.target.value)}

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Basic Price
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                
                                                </Row>

                                                  
                                               

                                                <>
                                                <Typography style={{ color: 'black', fontSize: 14, marginBottom: 3, fontWeight: 'bold' }}>Scheme</Typography>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6">
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            type="radio"
                                                            id="schemeYes"
                                                            value="yes"
                                                            checked={scheme === 'yes'}
                                                            onChange={handleSchemeChange}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            type="radio"
                                                            id="schemeNo"
                                                            value="no"
                                                            checked={scheme === 'no'}
                                                            onChange={handleSchemeChange}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                {scheme === 'yes' && (
                                                    <Row className="mb-3">
                                                    
                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Scheme Name</Typography>
                                                            <Form.Control
                                                                required
                                                                placeholder='Enter Scheme Name'
                                                                type="text"
                                                                value={sname}
                                                                onChange={(e) => setSname(e.target.value)}
                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Enter Valid Basic Price
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Amount</Typography>
                                                            <Form.Control
                                                                required
                                                                placeholder='Enter  Amount'
                                                                type="number"
                                                                value={scheamt}
                                                                onChange={(e) => setScheamt(e.target.value)}

                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Enter Valid Basic Price
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                )}
                                            </>




                                               
                                            </Row>
                                            {
                                                btnone && (
                                                    <Button type='submit' style={{ width: '6rem', height: '1.8rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                )
                                            }
                                            
                                                </Form>
                                            </div>
                                        ) : activeStep === 1 ? (
                                            <div>
                                            <div>
      
                                        {/* <Form noValidate validated={Validated2} onSubmit={formValidation2} >
                                            <div className='mb-4 mt-1'>
                                            </div>
                                           
                                            {
                                                btntwo && (
                                                    <Button type='submit' style={{ width: '6rem', height: '1.8rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                )
                                            }
                                           
                                                </Form> */}
                                                </div>
                                                </div>
                                            ) : null}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                {activeStep !== 0 && (
                                                <Button
                                                    color="inherit"
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                )}
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {activeStep === 0 && (
                                                <Button onClick={handleNext}>
                                                    Next
                                                </Button>
                                                )}
                                                {activeStep === 1 && (
                                                <Button  onClick={handleFinish}>
                                                    Finish
                                                </Button>
                                                )}
                                            </Box>
                                            </Box>

                        </div>
                    </DialogContent>
                </Dialog>

                <div>
                <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={openpd}
                    onClose={() => setOpenpd(!openpd)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                        <div className='mb-4 mt-1'>
                        <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Pricing Details</Typography>
                        </div>
                        <div className="row">
                        <div className="col-md-4">
                            <p><strong>Project Name:</strong> {pdprojname}</p>
                            <p><strong>BasePrice:</strong> {pdbp}</p>
                            <p><strong>East Amount:</strong> {pdeamt}</p>
                            <p><strong>Corner Amount:</strong> {pdcor}</p>
                        </div>
                        <div className="col-md-4">
                            <p><strong>West Amount:</strong> {pdwamt}</p>
                            <p><strong>Two Car Park:</strong> {pdtwo}</p>
                            <p><strong>Scheme Name:</strong> {pdsname}</p>
                            <p><strong>Park View Amount:</strong> {pdpv}</p>

                        </div>
                        <div className="col-md-4">
                            <p><strong>North Amount:</strong> {pdnamt}</p>
                            <p><strong>Three Car Park:</strong> {pdthre}</p>
                            <p><strong>Scheme Amount:</strong> {pdscheamt}</p>
                            <p><strong>One Car Park:</strong> {pdo}</p>

                        </div>
                        <Typography style={{  fontWeight: 'bold', color: 'grey', fontSize: 15 }}>FloorRise Charges</Typography>
                        <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>FloorName</th>
                                <th>Floorrise Charges</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                floorrisecharge.map((data) => {
                                    return(
                                        <tr>
                                    <td>{data.floorName}</td>
                                    <td>{data.floorRiseAmount}</td>
                                    </tr>
                                    )
                                })
                            }
                           
                        </tbody>
                    </table>

                    </div>
                        </div>
                        </DialogContent>
                    
                    </Dialog>
                </div>

               
            </div>
            </div>

        </div>
    );
}