import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { FaCaretLeft, FaRupeeSign } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'auto',
}));

export default function CreateCustomer() {

    const [email, setEmail] = React.useState()
    const [mobileNumber, setMobile] = React.useState()
    const [customerName, setCustomerName] = React.useState()
    const [budget, setBudget] = React.useState()
    const [flatImage, setFlatImage] = React.useState("")
    const [ProjectName, setProjectName] = React.useState()
    const [flat, setFlat] = React.useState()
    const [facingAmmount, setFacingAmmount] = React.useState('0')
    const [floorAmount, setFloorAmmount] = React.useState('0')
    const [Floorr, setFloorr] = React.useState('')
    const [cornor, setCornor] = React.useState()
    const [corneramount, setCornerAmount] = useState()


    const handleCornerCheckboxChange = (event) => {

        console.log(event.target.value);

        setCornor(event.target.value)

        setCornerAmount(event.target.value === 'Yes');

    };


    const [response2, setResponse2] = React.useState([])



    const get_basic = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse2(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        getLeadname()
        getcomval()
    }, [])

    const bookCustomer = () => {


        const formData = new FormData()

        formData.append('customerName', customerName)
        formData.append('mobileNumber', mobileNumber)
        formData.append('email', email)
        formData.append('budget', (parseInt(basicPrice) * parseInt(sqf) + parseInt(facingAmmount) + parseInt(floorAmount)))
        formData.append('unitNo', flat)
        formData.append('file', flatImage)
        formData.append('flatSize', sqf)
        formData.append('flatFace', facing)
        formData.append('floor', floor)
        formData.append('block', block)
        formData.append('projectName', ProjectName)
        formData.append('facingAmount', facingAmmount)
        formData.append('floorAmount', floorAmount)

        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/customer', formData).then((res) => {
            if (res.data.status === 200) {
                handleClose()
                setCustomerBookingInfo([])
                setCustomer([])
                setResponse2([])
                setResponse3([])
                setResponse4([])
                setResponse4([])
                setResponse5([])
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: `Customer is booked successfully ! userId: ${email} & password: ${res.data.password}`,
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    handleClose()
                    if (result.value) {
                        window.location.reload()
                    } else {
                        window.location.reload()
                    }
                })
            }
        })
    }

    const [customerBookingInfo, setCustomerBookingInfo] = React.useState([])

    const createCustomer = (projectName) => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/filter-customer-info', {
            projectName
        }).then((res) => {
            setCustomerBookingInfo(res.data)
        })
    }

    const [customer, setCustomer] = React.useState([])

    const customerData = (id) => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/filter-customer', {
            id
        }).then((res) => {
            setCustomer(res.data)
            setCustomerName(res.data[0].name)
            setMobile(res.data[0].mobile)
            setEmail(res.data[0].email)
            setBudget(res.data[0].budget)

        })
    }


    const [block, setBlock] = React.useState("")
    const [floor, setFloor] = React.useState("")
    const [sqf, setSqf] = React.useState()
    const [facing, setFacing] = React.useState("")
    const [fType, setFType] = React.useState("")
    const [other, setOther] = React.useState('')



    const [response3, setResponse3] = React.useState([])

    const get_block = (value) => {

        axios.post("https://buildfastvapi.emedha.in/project/api/v1/projectwise-blocks", {
            value
        }).then((res) => {

            setResponse3(res.data)
            setProjectName(value)
            getcomval(value)

        })
    }

    const [response4, setResponse4] = React.useState([])

    const get_floor = (value1, value2) => {

        axios.post("https://buildfastvapi.emedha.in/project/api/v1/blockwise-floor", {
            value1, value2
        }).then((res) => {

            setResponse4(res.data)
            setBlock(value1)

        })
    }

    const [response5, setResponse5] = React.useState([])



    const get_unit = () => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/select-unit", {
            projectName: ProjectName
        })
            .then((res) => {
                setResponse5(res.data)
                console.log(res.data);

            })
    }

    // const get_flat = (value1, value2, value3) => {

    //     axios.post("https://buildfastvapi.emedha.in/create-customer/api/v1/blockwise-flat", {
    //         value1, value2, value3
    //     }).then((res) => {

    //         setResponse5(res.data)
    //         setFloor(value1)
    //     })
    // }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };
    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            leadDataEdit()
        }

        setValidated(true);
    };
    const editAlert = () => {
        setOpen(false)
        handleClose()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        })
    }


    const [response6, setResponse6] = React.useState([])


    const get_other = (value1, value2, value3, value4) => {

        axios.post("https://buildfastvapi.emedha.in/create-customer/api/v1/blockwise-other", {
            value1, value2, value3, value4
        }).then((res) => {

            setResponse6(res.data)
            setFlat(value1)
            // setSqf(res.data[0].UnitSize)
            // setFType(res.data[0].UnitType)
            // setFacing(res.data[0].UnitFacing)
            createCustomer(ProjectName)
        })
    }

    const [basicPrice, setBasicPrice] = React.useState([])
    const get_pricing = (text) => {
        axios.post("https://buildfastvapi.emedha.in/create-customer/api/v1/basic-ammount", {
            text
        }).then((res) => {

            setBasicPrice(res.data[0].Basic_Amount)
            handleClickOpen()
        })
    }



    const flatImageValidation = (w) => {
        if (flatImage === "") {
            Swal.fire({
                icon: 'error',
                title: "Error !",
                text: "Please Select flat image .!",
                confirmButtonColor: "rgb(126, 110, 228)",
                cancelButtonColor: "#d33",
                showCancelButton: false,
                confirmButtonText: "Ok"
            }).then((result) => {
                handleClose()
                if (result.value) {
                    return
                }
            })
        } else {
            get_pricing(w)
        }
    }

    const [carPark, setCarPark] = React.useState("0")
    const [security, setSecurity] = React.useState("0")
    const [clubHouse, setClubHouse] = React.useState("0")

    const [ft, setFt] = React.useState('')

    const [responsegt, setResponsegt] = React.useState([])

    const get_floorr = (value1, value2) => {

        axios.post("https://buildfastvapi.emedha.in/project/api/v1/select-floor", {
            value1, value2
        }).then((res) => {

            setResponsegt(res.data)
            // console.log('HGJH', res.data);
            // setBlock(value1)
            setFt(value1)


        })
    }

    const [responset, setResponset] = React.useState([])

    const get_tower = (value1) => {

        axios.post("https://buildfastvapi.emedha.in/project/api/v1/projectwise-towers", {
            value1
        }).then((res) => {

            setResponset(res.data)
            console.log(res.data)
            setBlock(value1)


        })
    }

    const [responsegu, setResponsegu] = React.useState([])

    const get_unitu = (value) => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/projectwise/unitss", {
            value
        })
            .then((res) => {
                setResponsegu(res.data)
                console.log(res.data);
                setFloorr(value)

            })
    }

    const [camt, setCamt] = useState()
    const [usize, setUsize] = useState()
    const [crn, setCrn] = useState()
    const [ufac, setUfac] = useState()
    const [facamt, setFacamt] = useState()
    const [flramt, setFlramt] = useState()
    const [tval, setTval] = useState()

    const get_corn = (text) => {
        axios.post("https://buildfastvapi.emedha.in/create-customer/api/v1/unt", {
            text
        })
            .then((res) => {

                setUsize(res.data.UnitSize)
                setCrn(res.data.Cornor)
                setUfac(res.data.Facing)
                setCamt(res.data.CornorAmount)
                setFacamt(res.data.Facingwise_Amount)
                setFlramt(res.data.Floorwise_Amount)
                setTval(res.data.TotalValue)

            })
    }

    const [usta, setUsta] = useState('')
    const handleUnitClick = (flag, unit) => {
        if (flag === 0) {
            setOther(unit)
            get_corn(unit)
            setUsta(unit)

            console.log(`Clicked on unit: ${unit}`);
        }

    };

    const [res, setRes] = React.useState([])

    const getLeadname = () => {
        axios.get('https://buildfastvapi.emedha.in/api/v1/lead-data').then((res) => {
            setRes(res.data.data)
            // console.log("leaddata",res.data.data);
            // setIsFilter(true)
        })
    }

    const [nname, setNname] = useState('')
    const [nmobile, setNmobile] = useState('')
    const [nemail, setNemail] = useState('')
    const [nst, setNst] = useState('')
    const [nptym, setNptym] = useState('')
    const [ngen, setNgen] = useState('')
    const [nid, setNid] = useState('')



    const getdetls = (value) => {
        console.log({ value });
        axios.post('https://buildfastvapi.emedha.in/api/v1/get-lead-details', {
            value
        })
            .then(res => {
                setNid(res.data.Id)
                setNname(res.data.name)
                setNmobile(res.data.mobile)
                setNemail(res.data.email)
                setNgen(res.data.gender)
                setNptym(res.data.pretime)
                setNst(res.data.sourceType)

            })
    }

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;


        if (target.checked) {
            setSelectedCheckboxes([...selectedCheckboxes, name]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(checkbox => checkbox !== name));
        }
    };


    const calculateTotalAmount = () => {
        let totalAmount = 0;
        selectedCheckboxes.forEach((checkbox) => {
            switch (checkbox) {
                case 'carPark':
                    totalAmount += 200000;
                    break;
                case 'clubHouse':
                    totalAmount += 200000;
                    break;
                case 'security':
                    totalAmount += 200000;
                    break;
                default:
                    break;
            }
        });
        return totalAmount;
    };

    const bookTot = calculateTotalAmount() + tval

    const bookCus = () => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/customer', {
            customerName: nname, mobileNumber: nmobile, email: nemail, unitNo: usta, flatFace: ufac, floor: Floorr, block, Tower: ft, projectName: ProjectName, facingAmount: facamt, floorAmount: flramt, carnerAmt: camt, TotalAmount: bookTot, Cornor: crn
        })
            .then(res => {
                console.log("bc", res.data);
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: `Customer is booked successfully !`,
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    handleClose()
                    if (result.value) {
                        window.location.reload()
                    } else {
                        window.location.reload()
                    }
                })
            })
    }

    const vs = 20000;

    const [carpa, setCarpa] = useState(0)
    const [clubho, setClubho] = useState('')
    const [sec, setSec] = useState('')

    const getcomval = (text) => {
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/projcomm', {
            text
        })
            .then((res => {

                setCarpa(res.data.CarPark);
                setClubho(res.data.ClubHouse);
                setSec(res.data.Security);
                console.log("sas", res.data.CarPark);
            }))
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (value) => {
        setSelectedOptions((prevOptions) => {
            if (prevOptions.includes(value)) {
                return prevOptions.filter((option) => option !== value);
            } else {
                return [...prevOptions, value];
            }
        });
    };


    return (
        <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    {/* <Item className='card' style={{ height: "96.7vh", position: 'relative' }} sx={{ backgroundColor: 'rgb(126, 110, 228)', justifyContent: 'center', overflow: 'auto' }}> */}

                    <Item className='card col-10 mx-auto' >
                        <Typography style={{ fontSize: 21, color: 'rgb(126, 110, 228)' }} ><FaCaretLeft onClick={() => window.location.reload()} style={{ marginTop: '-0.3rem', cursor: 'pointer', color: 'rgb(126, 110, 228)' }} /> Book Customer</Typography>
                        <Form noValidate validated={Validated} onSubmit={formValidation} >
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 14 }}>Project Name</Typography>
                                <Form.Select
                                    required
                                    onChange={(e) => get_block(e.target.value)}
                                    type="text"

                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                >
                                    <option dis>Select One</option>
                                    {
                                        response2.map((data) => {
                                            return (
                                                <option value={data.ProjectName} >{data.ProjectName}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Valid manager
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* {
                                (response3.length !== 0) && ( */}
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 13 }}>Block {carpa}</Typography>
                                <Form.Select
                                    required
                                    onChange={(e) => get_tower(e.target.value)}
                                    type="text"

                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                >
                                    <option>Select Block Name</option>

                                    {
                                        response3.map((data, index) => (
                                            <React.Fragment key={index}>
                                                {data.Blocks.split(',').map((block, blockIndex) => (
                                                    <option key={blockIndex} >{block.trim()}</option>
                                                ))}
                                            </React.Fragment>

                                        ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Block name
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* )
                            }

                                {
                                (block !== "") && ( */}
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                <Form.Select
                                    required
                                    onChange={(e) => get_floorr(e.target.value, ProjectName)}
                                    type="text"

                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                >
                                    <option>Select Towers</option>

                                    {
                                        responset.map((data, index) => (
                                            <React.Fragment key={index}>
                                                {data.Towers.split(',').map((block, blockIndex) => (
                                                    <option key={blockIndex}>{block.trim()}</option>
                                                ))}
                                            </React.Fragment>
                                        ))

                                    }

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Tower name
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* )
                            }

                            {
                                (ft !== "") && ( */}
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                <Form.Select
                                    required
                                    // onChange={(e) => get_flat(e.target.value, ProjectName, block)}
                                    onChange={(e) => { get_unitu(e.target.value) }}
                                    type="text"
                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                >
                                    <option>Select Floor </option>


                                    {
                                        responsegt.map((data, index) => (
                                            <React.Fragment key={index}>
                                                {data.selectType.split(',').map((block, blockIndex) => (
                                                    <option key={blockIndex} >{block.trim()}</option>
                                                ))}
                                            </React.Fragment>

                                        ))

                                    }

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Floor name
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* )
                            }
                               
                            {
                                Floorr != '' && ( */}
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit</Typography>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {
                                        responsegu.map((data, index) => (
                                            <React.Fragment key={index}>
                                                {data.unit_name.split(',').map((block, blockIndex) => (
                                                    <button
                                                        key={blockIndex}
                                                        style={{
                                                            padding: '0.42rem',
                                                            margin: '4px',
                                                            backgroundColor: data.flag === 0 ? 'green' : (data.flag === 1 ? 'red' : 'gray'),
                                                            color: 'white',
                                                            cursor: data.flag === 1 || data.flag === 2 ? 'not-allowed' : 'pointer',
                                                        }}
                                                        onClick={() => handleUnitClick(data.flag, block.trim())}
                                                        disabled={data.flag === 1 || data.flag === 2}
                                                    >
                                                        {block.trim()}
                                                    </button>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Unit name
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* )
                            }

                            {
                                other != '' && ( 
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_other(e.target.value, ProjectName, block, floor)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Flat</option>
                                            <option>Select Flat</option>

                                            {
                                                response5.map((data) => {
                                                    return (
                                                        <option>{data.UnitNo}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )
                            } */}
                            <Row>
                                {cornor !== '' && (
                                    <>


                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <label style={{ color: 'gray', fontSize: 13 }}>Corner</label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="cornor"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        value={true}
                                                        checked={crn === 'Yes'}
                                                    // onChange={handleCornerCheckboxChange}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="cornor"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        value={false}
                                                        checked={crn === 'No'}
                                                    // onChange={handleCornerCheckboxChange}
                                                    />
                                                </div>
                                            ))}
                                            <Form.Control.Feedback type="invalid">
                                                Please select Cornor option
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {corneramount && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom03">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Corner Amount*</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setCornor(e.target.value)}
                                                    type="number"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid corner amount
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </>
                                )}
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Size</Typography>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={usize}
                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Unit size
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row>
                                {/* {
                                    (response6.length !== 0) && ( 
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Type</Typography>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={fType}
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter project name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                     )
                                }*/}

                                {/*  {
                                    (response6.length !== 0) && ( */}
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Face</Typography>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={ufac}
                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter unit face
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* )
                                }

                                {
                                    (response6.length !== 0) && ( */}
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Total Amount </Typography>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={bookTot}
                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Total Amount
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* )
                                }*/}

                                {/*  {
                                    (response6.length !== 0) && ( 
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Facingwise Amount</Typography>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={facing === 'North' || facing === 'South' ? 100 : 200}
                                        onChange={(e) => setFacingAmmount(e.target.value)}
                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter project name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                )
                                }


                                {
                                    (response6.length !== 0) && ( 
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Floorwise Amount</Typography>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={0}
                                        onChange={(e) => setFloorAmmount(e.target.value)}
                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter project name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                )
                                } */}

                            </Row>

                            {/* {
                                (response6.length !== 0) && ( */}
                            {/* <div class="radio-wrapper">
                                <div className="radio-container d-flex" style={{ marginBottom: 15 }}>
                                    <Typography>Common</Typography>
                                    <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                        <input onChange={(e) => setCarPark(e.target.value)} type="radio" value="50000" id="male" />
                                        <label for="male">Car Park</label>
                                    </div>
                                    <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                        <input onChange={(e) => setClubHouse(e.target.value)} type="radio" value="10000" />
                                        <label for="female">Club House</label>
                                    </div>
                                    <div>
                                        <input onChange={(e) => setSecurity(e.target.value)} type="radio" value="20000" />
                                        <label for="female">Security</label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="checkbox-wrapper">
                                <div className="checkbox-container d-flex" style={{ marginBottom: 15 }}>
                                    <Typography>Common</Typography>
                                    <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                        <input
                                            onChange={() => handleCheckboxChange("50000")}
                                            type="checkbox"
                                            value="50000"
                                            id="carPark"
                                            checked={selectedOptions.includes("50000")}
                                        />
                                        <label htmlFor="carPark">Car Park</label>
                                    </div>
                                    <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                        <input
                                            onChange={() => handleCheckboxChange("10000")}
                                            type="checkbox"
                                            value="10000"
                                            id="clubHouse"
                                            checked={selectedOptions.includes("10000")}
                                        />
                                        <label htmlFor="clubHouse">Club House</label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => handleCheckboxChange("20000")}
                                            type="checkbox"
                                            value="20000"
                                            id="security"
                                            checked={selectedOptions.includes("20000")}
                                        />
                                        <label htmlFor="security">Security</label>
                                    </div>
                                </div>
                            </div>
                            {/* <Typography>Selected Amount: {calculateTotalAmount()}</Typography> */}

                            {/* <input type="text" value={totalValue} readOnly /> */}

                            {/* )
                            }

                            {
                                (response6.length !== 0) && ( */}
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Typography style={{ color: 'gray', fontSize: 13 }}>Customer</Typography>
                                <Form.Select
                                    required
                                    onChange={(e) => getdetls(e.target.value)}
                                    type="text"

                                    style={{ padding: '0.42rem', marginBottom: 14 }}

                                >
                                    <option>Select customer</option>
                                    {
                                        res.map((item => (
                                            <option>{item.name},{item.mobile}</option>
                                        )))
                                    }

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter project Customer
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* )
                            } */}

                            <Row>
                                {/* {
                                    (customer.length !== 0) && ( */}
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Name</Typography>
                                    <Form.Control
                                        required

                                        type="text"
                                        value={nname}
                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* )
                                }
                                {
                                    (customer.length !== 0) && ( */}
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Email</Typography>
                                    <Form.Control
                                        required


                                        type="text"
                                        value={nemail}
                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Email
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* )
                                }
                                {
                                    (customer.length !== 0) && ( */}
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile</Typography>
                                    <Form.Control
                                        required


                                        type="text"
                                        value={nmobile}
                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Mobile
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* )
                                } */}
                                {/* {
                                    (customer.length !== 0) && (
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Flat Image</Typography>
                                            <Form.Control
                                                required
                                                onChange={(e) => setFlatImage(e.target.files[0])}

                                                type="file"

                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Blocks
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )
                                } */}
                            </Row>

                            {/* <Button onClick={() => flatImageValidation(ProjectName)}  style={{ backgroundColor: 'rgb(126, 110, 228)', color: 'white', width: '11rem', marginTop: 7 }} >Book Customer</Button> */}
                            <div>
                                            <Button type='submit' style={{ width: '10rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Book Customer</Button>
                                        </div>
                            {/* <Button onClick={bookCus} style={{ backgroundColor: 'rgb(126, 110, 228)', color: 'white', width: '11rem', marginTop: 7 }} >Book Customer</Button> */}
                        </Form>
                    </Item>
                    {/* </Item> */}
                </Grid>
            </Grid>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    maxWidth='lg'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ position: 'relative', backgroundColor: 'rgb(126, 110, 228)', width: '39rem', padding: '1rem' }} >
                        <Typography style={{ color: 'white', fontSize: '1.1rem' }} >Email Reciept ------ Customer</Typography>
                        <div style={{ marginTop: 10 }}>

                            <Typography style={{ color: 'white', fontSize: '1.1rem', borderBottom: '1px solid white' }} >Customer Details</Typography>
                            <br />
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Customer Name:  <span>{customerName}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Customer Email:  <span>{email}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Customer Mobile:  <span>{mobileNumber}</span></Typography>
                        </div>


                        <div style={{ marginTop: 31 }}>

                            <Typography style={{ color: 'white', fontSize: '1.1rem', borderBottom: '1px solid white' }} >Flat Details</Typography>
                            <br />
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex', display: 'flex' }}>Project Name:  <span>{ProjectName}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Block:  <span>{block}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Floor:  <span>{floor}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Flat No:  <span>{flat}</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 6, display: 'flex' }}>Flat Size:  <span>{sqf}</span></Typography>
                        </div>

                        <div style={{ marginTop: 31 }}>
                            <Typography style={{ color: 'white', fontSize: '1.1rem', borderBottom: '1px solid white' }} >Pricing</Typography>
                            <br />
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Basic Price:  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{basicPrice} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Flat Amount:  <span>Basic Price: {basicPrice} * Flat Size: {sqf} = <FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{parseInt(basicPrice) * parseInt(sqf)} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Facingwise Amount:  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{facingAmmount} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Floorwise Amount :  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{floorAmount} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}><span>Basic Price: {basicPrice} * Flat Size: {sqf} + Facing Amount: {facingAmmount} + Floor Amount: {floorAmount} <br /> <span style={{ fontSize: '1.3rem' }}> Total Amount <FaRupeeSign style={{ fontSize: 18, marginTop: -2 }} />{parseInt(basicPrice) * parseInt(sqf) + parseInt(facingAmmount) + parseInt(floorAmount)} /-</span></span></Typography>
                        </div>

                        <div style={{ marginTop: 31 }}>
                            <Typography style={{ color: 'white', fontSize: '1.1rem', borderBottom: '1px solid white' }} >Common</Typography>
                            <br />
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Car Park:  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{carPark} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Club House:  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{clubHouse} /-</span></Typography>
                            <Typography style={{ color: 'white', fontSize: '0.8rem', marginBottom: 8, display: 'flex' }}>Security:  <span><FaRupeeSign style={{ fontSize: 11, marginTop: -2 }} />{security} /-</span></Typography>

                        </div>

                        <Typography style={{ color: 'white', fontSize: '0.9rem', borderTop: '0.8px solid white', paddingTop: 3, position: 'absolute', right: 6, bottom: 3, display: 'flex' }} >Book:  <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => bookCustomer()} >Yes</span> / <span style={{ color: 'lightgray', cursor: 'pointer' }} onClick={() => handleClose()} >No</span></Typography>

                    </div>

                </Dialog>
            </div>
        </Box>
    );
}