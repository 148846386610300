import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography, colors } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import TopCard from './TpoCard';
import axios from 'axios'
import MuiAlert from "@material-ui/lab/Alert";
import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Pagination from '@mui/material/Pagination';



export default function LeadManagementTable({ lw }) {



    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(leadManagementTable);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        const excelBuffer = XLSX.write(workbook, { type: "array" });
        saveAs(new Blob([excelBuffer]), "data.xlsx");
    };

    //-------primary------//

    const [timings, setTimings] = useState()

    const [wapp, setWapp] = useState()
    const [isWhatsappNo, setIsWhatsappNo] = useState(false);

    const [whatsappMobile, setWhatsappMobile] = useState('');



    const handleWhatsappCheckboxChange = (event) => {
        setIsWhatsappNo(event.target.value === 'No');
    };



    //-----end-----//


    const [open, setOpen] = React.useState(false);
    const [isVeiw, setIsVeiw] = useState(false)


    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const currentDate = new Date();


        const ageInMilliseconds = currentDate - dobDate;
        const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

        setAge(ageInYears);
    };

    const handleDobChange = (event) => {
        const newDob = event.target.value;
        setDob(newDob);
        calculateAge(newDob);
    };



    const handleIntrestCheckboxChange = (event) => {
        setIntrest(event.target.value);
        setSiteVisit('');
        setLater('');
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6}
            variant="filled" {...props} />;
    }

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            leadDataEdit()
        }

        setValidated(true);
    };

    const [leadManagementTable, setLeadManagementTable] = useState([])
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [dob, setDob] = useState()
    const [gender, setGen] = useState()
    const [age, setAge] = useState()
    const [street, setStreet] = useState()
    const [address, setAddress] = useState()
    const [area, setArea] = useState()
    const [state, setState] = useState()
    const [pinCode, setPinCode] = useState()
    const [empType, setEmpType] = useState('Business')
    const [jobTitle, setJobTitle] = useState()
    const [industry, setIndustry] = useState()
    const [sourceType, setSourceType] = useState('Website')
    const [leadTime, setLeadTime] = useState()
    const [leadStatus, setLeadStatus] = useState('New')
    const [Intrest, setIntrest] = useState('')
    const [budget, setBudget] = useState()
    const [financeType, setFinanceType] = useState('Own')
    const [salesExecutive, setSalesExecutive] = useState()
    const [leadDate, setLeadDate] = useState()
    const [id, setId] = useState()
    const [sitevisit, setSiteVisit] = useState('');
    const [later, setLater] = useState('');
    const [remarks, setRemarks] = useState('');

    const [isAdd, setIsAdd] = useState(false)




    const lmdbstore = () => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/lead-managements', {
            name, email, mobile, dob, gender, address, age, street, area, state, pinCode, empType, jobTitle, industry, sourceType, leadTime, leadStatus, Intrest, budget, financeType, salesExecutive, leadDate, sitevisit, later, remarks
        }).then((res) => {
            if (res.data.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })

                setTimeout(() => setIsAdd(false), 2000)
                setValidated(false)
                setOpen(false)
                leadDataGet()
            }
        })
    }

    const editAlert = () => {
        setOpen3(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Edit"
        }).then((result) => {
            if (result.value) {
                leadDataEdit()
            }
        })
    }

    const leadDataEdit = () => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/lead-edit', {
            name: nname, email: nemail, mobile: nmobile, dob, gender: ngen, address, age, street, area, state, pinCode, empType, sourceType: nst, budget, financeType, salesExecutive, sitevisit, later, remarks, leadDate, pretime: nptym, id: nid
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    leadDataGet()
                    setIsVeiw(false)
                    handleClose()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Lead is added successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    React.useEffect(() => {
        leadDataGet()
    }, [])

    const [leadData, setLeadData] = useState([])

    const leadDataGet = () => {
        axios.get('https://buildfastvapi.emedha.in/api/v1/lead-data').then((res) => {
            if (res.data.status === 200) {
                setLeadData(res.data.data)
            }
        })
    }

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                leadDataDelete(id)
            }
        })
    }

    const leadDataDelete = (id) => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/delete-lead', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                leadDataGet()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Lead is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const allocateAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to Allocate this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                leadDataAllocate(id)
            }
        })
    }
    const viewData = (id) => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/view-lead', {
            id
        }).then((res) => {
            setName(res.data.name)
            setEmail(res.data.email)
            setMobile(res.data.mobile)
            setDob(res.data.dob)
            setGen(res.data.gender)
            setAge(res.data.age)
            setStreet(res.data.street)
            setAddress(res.data.address)
            setArea(res.data.area)
            setState(res.data.state)
            setPinCode(res.data.pinCode)
            setEmpType(res.data.empType)
            setJobTitle(res.data.jobTitle)
            setIndustry(res.data.industry)
            setSourceType(res.data.sourceType)
            setLeadTime(res.data.leadTime)
            setLeadStatus(res.data.leadStatus)
            setIntrest(res.data.Intrest)
            setBudget(res.data.budget)
            setFinanceType(res.data.financeType)
            setSalesExecutive(res.data.salesExecutive)
            setLeadDate(res.data.leadDate)
            setSiteVisit(res.data.siteVisit)
        })
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open3, setOpen3] = useState(false)

    const handleClose3 = () => {
        setOpen3(false)
    }

    const leadDataSearch = (text) => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/search-lead', {
            text
        }).then((res) => {

            setLeadData(res.data)

        })
    }

    const [businessLe, setBusinessLe] = useState([])
    const [professionalLe, setProfesionalLe] = useState([])
    const [govtLe, setGovtLe] = useState([])
    const [privateJob, setPrivateJob] = useState([])
    const [all, setAll] = useState([])
    const [newStatus, setNewStatus] = useState([])
    const [FollowedUp, setFollowedUp] = useState([])
    const [ContactMadeStatus, setContactMadeStatus] = useState()


    const leadLe = (text) => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/lead/type-length', {
            text
        }).then((res) => {

            setBusinessLe(res.data.business)
            setProfesionalLe(res.data.professional)
            setGovtLe(res.data.govt)
            setPrivateJob(res.data.private)
            setAll(res.data.all)
            setFollowedUp(res.data.followedStatus)
            setContactMadeStatus(res.data.ContactMadeStatus)
            setNewStatus(res.data.newStatus)

        })
    }

    React.useEffect(() => {
        leadLe()
        getLeadname()
    }, [])

    const [res, setRes] = React.useState([])

    const getLeadname = () => {
        axios.get('https://buildfastvapi.emedha.in/api/v1/lead-data').then((res) => {
            setRes(res.data.data)
            // console.log("leaddata",res.data.data);
            // setIsFilter(true)
        })
    }

    const [nname, setNname] = useState('')
    const [nmobile, setNmobile] = useState('')
    const [nemail, setNemail] = useState('')
    const [nst, setNst] = useState('')
    const [nptym, setNptym] = useState('')
    const [ngen, setNgen] = useState('')
    const [nid, setNid] = useState('')



    const getdetls = (value) => {
        console.log({ value });
        axios.post('https://buildfastvapi.emedha.in/api/v1/get-lead-details', {
            value
        })
            .then(res => {
                setNid(res.data.Id)
                setNname(res.data.name)
                setNmobile(res.data.mobile)
                setNemail(res.data.email)
                setNgen(res.data.gender)
                setNptym(res.data.pretime)
                setNst(res.data.sourceType)

            })
    }


    const [isFilter, setIsFilter] = useState(false)

    const leadDataFilter = () => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/filter-lead', {
            empType,
            leadStatus
        }).then((res) => {
            setLeadData(res.data)
            setIsFilter(true)
        })
    }

    const confirmAlert = (id) => {
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to confirm !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                confirm(id)
            }
        })
    }

    const confirm = (id) => {
        axios.post('https://buildfastvapi.emedha.in/api/v1/cunfirm-customer', {
            id
        }).then((res) => {
            Swal.fire({
                icon: 'success',
                title: "Confirm !",
                text: "Your confimation is successfully done !",
                confirmButtonColor: "rgb(126, 110, 228)",
                cancelButtonColor: "#d33",
                showCancelButton: false,
                confirmButtonText: "Ok"
            }).then((result) => {
                setIsVeiw(false)
                leadDataGet()
                if (result.value) {
                    return
                }
            })
        })
    }

    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = leadData.slice(indexOffpage, indexOflpage)

    return (
        <div>
            {
                isAdd && (
                    <div style={{ marginBottom: 6 }}>
                        <Alert severity="success">Data add successfully thank you !</Alert>
                    </div>
                )
            }
            <TopCard professional={professionalLe} privates={privateJob} govt={govtLe} business={businessLe} all={all} followedStatus={FollowedUp} ContactMadeStatus={ContactMadeStatus} newStatus={newStatus} ></TopCard>
            {
                !isVeiw && (
                    <div className='leadManagementTable'>
                        <Paper elevation={0} >

                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}
                                {/* <div className='card mt-3 mb-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Select Emp Type</Typography>
                                        <select onChange={(e) => {
                                            setEmpType(e.target.value)
                                        }} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} >
                                            <option>Business</option>
                                            <option>Govt job</option>
                                            <option>Private Job</option>
                                            <option>Professional Job</option>
                                        </select>
                                    </div>
                                    <div>
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Select Lead Status</Typography>
                                        <select onChange={(e) => {
                                            setLeadStatus(e.target.value)
                                        }} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} >
                                            <option>New</option>
                                            <option>Contact-Made</option>
                                            <option>Fallowedup</option>
                                        </select>
                                    </div> 
                                    {
                                        !isFilter && (
                                            <div>
                                                <Button onClick={leadDataFilter} style={{ width: '7rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginTop: 16.4 }}>Filter</Button>
                                            </div>
                                        )
                                    }
                                    {
                                        isFilter && (
                                            <div>
                                                <Button onClick={() => {
                                                    setIsFilter(false)
                                                    leadDataGet()
                                                }} style={{ width: '7rem', height: '2.3rem', backgroundColor: 'red', color: 'white', marginTop: 16.4 }}>Cancel</Button>
                                            </div>
                                        )
                                    }
                                </div> */}
                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>
                                        <input onChange={(e) => leadDataSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    <div>
                                        <Button onClick={handleClickOpen} style={{ width: '13rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Secondary Lead</Button>
                                    </div>
                                </div>
                                <Table style={{ position: 'relative', top: '1.3rem' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                                            >
                                                Name
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                Mobile
                                            </TableCell>

                                            {/* <TableCell

                                                align={"start"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                DOB
                                            </TableCell> */}

                                            {/* <TableCell

                                                align={"left"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                Age
                                            </TableCell>

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                Adress
                                            </TableCell> */}


                                            {/* <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Area
                                            </TableCell> */}

                                            {/* <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Pincode
                                            </TableCell>*/}

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Employment Type
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Finance
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Budget
                                            </TableCell>
                                            {/* <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Intrest
                                            </TableCell> */}
                                            {/* <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Site Visit
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Later
                                            </TableCell> */}



                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', width: 18 }}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {currentpost
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }

                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57 }}>
                                                            <div style={{ border: `3px solid ${color}`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%' }}>
                                                                <FaUserCircle style={{ color: 'rgb(126, 110, 228)', fontSize: 24 }} />
                                                            </div>
                                                        </TableCell> */}
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.name}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{row.mobile}</button>
                                                        </TableCell>
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.dob}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.age}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.address}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.intrest}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.area}</TableCell> */}


                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.pincode}</TableCell>*/}
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.empType}</TableCell>
                                                        <TableCell align={"start"} npm
                                                            style={{ top: 57, fontSize: 12 }}>{row.financeType}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.budget}</TableCell>

                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.sitevisit}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.later}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.area}</TableCell> */}
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.leadTime}</TableCell> */}

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                            <div style={{ display: 'flex' }} >
                                                                {/* <Button onClick={() => allocateAlert(row.Id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Allocate</Button> */}
                                                                <div>
                                                                    <IconButton
                                                                        id="fade-button"
                                                                        aria-controls={open2 ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open2 ? 'true' : undefined}
                                                                        onClick={(e) => {
                                                                            handleClick(e.currentTarget)
                                                                            setId(row.Id)
                                                                        }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="fade-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'fade-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open2}
                                                                        onClose={handleClose2}
                                                                        TransitionComponent={Fade}
                                                                    >
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setIsVeiw(true)
                                                                                }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem>
                                                                            )

                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setOpen3(true)

                                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                                                            )
                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => deleteAlert(row.Id)}><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                            )
                                                                        }
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    leadData.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                            <Pagination count={Math.ceil(leadData.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                        </div>
                    </div>
                )
            }
            {
                isVeiw && (
                    <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative', }} >
                        <FaWindowClose onClick={() => {
                            setIsVeiw(false)
                            leadDataGet()
                            setIsFilter(false)
                            setEmpType('Business')
                            setLeadStatus('New')
                        }} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} ></FaWindowClose>
                        <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }} ><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></Typography>
                            <Typography>Lead customers information details</Typography>
                        </div>
                        <br />
                        <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>

                            <div className='card' style={{ paddingTop: 43, border: '1px solid rgb(126, 110, 228)', borderRadius: '0.3rem', padding: 11, flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Name:</span> {name}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>DOB:</span> {dob}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Age:</span> {age}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Area:</span> {area}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Address:</span> {address}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>pincode:</span> {pinCode}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Employment:</span> {empType}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Finance:</span> {financeType}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Budget:</span> {budget}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Intrest:</span> {Intrest}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Site Visit:</span> {sitevisit}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Later:</span> {later}</Typography>
                                </div>


                                {/* <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>State:</span> {state}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Pin Code:</span> {pinCode}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Employment Type:</span> {empType}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Job Title:</span> {jobTitle}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Industry:</span> {industry}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Source Type:</span> {sourceType}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>DOB:</span> {dob}</Typography>
                                </div>

                                <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Lead Status:</span> {leadStatus}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Lead Time:</span> {leadTime} &  <span style={{ fontSize: 17, color: 'black' }}>Lead Date:</span> {leadDate}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Product Intrest:</span> {productIntrest}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Budget:</span> {budget}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Finance Type:</span> {financeType}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Sales Axecutive:</span> {salesAxecutive}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Gender:</span> {gender}</Typography>
                                </div> */}

                            </div>
                            <div style={{ marginTop: 15 }}>
                                <center>
                                    <Button onClick={exportToExcel} style={{ backgroundColor: 'green', color: 'white', fontSize: 14, height: '2.3rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Excel</Button>

                                    <Button onClick={() => {


                                    }} style={{ backgroundColor: 'red', color: 'white', fontSize: 14, height: '2.3rem', position: 'relative', top: '0.4rem', left: '-0.2rem', marginLeft: 3 }} >Pdf</Button>
                                </center>
                            </div>
                        </div>
                    </div>
                )
            }
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "74rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <center><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></center>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Addding lead details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Row>
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <div style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Name</Typography>
                                                <select onChange={(e) => getdetls(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                    <option>--Select--</option>
                                                    {
                                                        res.map((item => (
                                                            <option>{item.name},{item.mobile}</option>
                                                        )))
                                                    }
                                                </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Source type
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Name *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nname}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Email *</Typography>
                                            <Form.Control
                                                value={nemail}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid email
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {wapp !== '' && (
                                            <>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile *</Typography>
                                                    <Form.Control
                                                        required
                                                        disabled
                                                        value={nmobile}
                                                        type="number"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter valid mobile
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                {isWhatsappNo && (
                                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                                        <Typography style={{ color: 'gray', fontSize: 13 }}>WhatsApp Mobile *</Typography>
                                                        <Form.Control
                                                            required
                                                            onChange={(e) => setWhatsappMobile(e.target.value)}
                                                            type="number"
                                                            disabled
                                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please Enter Valid WhatsApp mobile
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                )}
                                            </>
                                        )}

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Gender</Typography>
                                            <Form.Control
                                                required
                                                value={ngen}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Date of Birth
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Preffered Timings</Typography>
                                            <Form.Control

                                                disabled
                                                value={nptym}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid mobile
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Source Type</Typography>
                                            <Form.Control

                                                disabled
                                                value={nst}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid mobile
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>D.O.B</Typography>
                                        <Form.Control
                                            required
                                            onChange={handleDobChange}
                                            type="Date"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />

                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Age</Typography>
                                        <Form.Control
                                            required
                                            value={age}
                                            type="number"
                                            readOnly
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid Age
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Area</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setArea(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Area
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Street</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setStreet(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Address</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Address
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Pincode</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setPinCode(e.target.value)}
                                            type="number"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Pincode
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Employment Type</Typography>
                                            <Form.Select onChange={(e) => setEmpType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Business</option>
                                                <option>Govt job</option>
                                                <option>Private Job</option>
                                                <option>Professional Job</option>
                                            </Form.Select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Employment type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Finance Type</Typography>
                                            <select onChange={(e) => setFinanceType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Own</option>
                                                <option>Loan</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid finance Type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Budget</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBudget(e.target.value)}
                                            type="number"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid budget
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Intrested *</Typography>
                                            <select
                                                onChange={(e) => setIntrest(e.target.value)}
                                                style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }}
                                            >
                                                <option>--Select--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                                <option>Later</option>
                                            </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                            </Form.Control.Feedback>
                                        </Form.Group> */}
                                    <>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <label style={{ color: 'gray', fontSize: 13 }}>Interested *</label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        value="Yes"
                                                        onChange={(e) => {
                                                            setIntrest(e.target.value),
                                                                setLater('')
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        value="No"
                                                        onChange={() => setIntrest('')}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Later"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        value="Later"
                                                        onChange={(e) => setLater(e.target.value)}
                                                    />
                                                </div>
                                            ))}
                                            <Form.Control.Feedback type="invalid">
                                                Please select Intrest option
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                        {Intrest !== '' && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Site Visit</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setSiteVisit(e.target.value)}
                                                    type="datetime-local"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid SiteVisit
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}

                                        {later !== '' && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Later</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setLater(e.target.value)}
                                                    type="date"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />

                                            </Form.Group>
                                        )}
                                    </>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Remarks *</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setRemarks(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Remarks
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {/*                                     
                                    {Intrest !== "" && (
        <>
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <div style={{ marginBottom: 14 }}>
              <Typography style={{ color: 'gray', fontSize: 13 }}>Interest</Typography>
              <select
                onChange={(e) => setIntrest(e.target.value)}
                style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }}
              >
                 <option>--Select--</option>
                <option>Yes</option>
                <option>No</option>
                <option>Later</option>
              </select>
            </div>
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Name Number
            </Form.Control.Feedback>
          </Form.Group>

          {Intrest === "Yes" && (
      <Form.Group as={Col} md="2" controlId="validationCustom01">
        <Typography style={{ color: 'gray', fontSize: 13 }}>Site Visit</Typography>
        <Form.Control
          required
          onChange={(e) => setSiteVisit(e.target.value)}
          type="datetime-local"
          style={{ padding: '0.42rem', marginBottom: 14 }}
        />
        <Form.Control.Feedback type="invalid">
          Please Enter Valid SiteVisit
        </Form.Control.Feedback>
      </Form.Group>
    )}

          {Intrest === "Later" && (
            <Form.Group as={Col} md="3" controlId="validationCustom01">
              <Typography style={{ color: 'gray', fontSize: 13 }}>Later</Typography>
              <Form.Control
                required
                onChange={(e) => setLater(e.target.value)}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Valid later
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </>
      )}
                                   */}

                                    {/* <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Street</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setStreet(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Area</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setArea(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Address</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid address
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>DOB</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setDob(e.target.value)}
                                            type="date"


                                            style={{ padding: '0.42rem', marginBottom: 14 }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid dob
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Job Title</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setJobTitle(e.target.value)}
                                            type="text"


                                            style={{ padding: '0.42rem', marginBottom: 15 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid job title
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>State</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setState(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter state
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Pin Code</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setPinCode(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid pin Code
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <div class="radio-wrapper">
                                            <div className="radio-container d-flex" style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>GENDER</Typography>
                                                <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                                    <input onChange={(e) => setGen(e.target.value)} type="radio" name="gender" value="Male" id="male" />
                                                    <label for="male">MALE</label>
                                                </div>
                                                <div>
                                                    <input onChange={(e) => setGen(e.target.value)} type="radio" name="gender" value="Female" />
                                                    <label for="female">FEMALE</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Select Emp Type</Typography>
                                            <select onChange={(e) => setEmpType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Business</option>
                                                <option>Govt job</option>
                                                <option>Private Job</option>
                                                <option>Professional Job</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Select Lead Status</Typography>
                                            <select onChange={(e) => setLeadStatus(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>New</option>
                                                <option>Contact-Made</option>
                                                <option>Followedup</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Product Interest</Typography>
                                            <select onChange={(e) => setProductIntrest(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Yes</option>
                                                <option>No</option>

                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Finance Type</Typography>
                                            <select onChange={(e) => setFinanceType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Own</option>
                                                <option>Loan</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Industry</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setIndustry(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid industry
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Source Type</Typography>
                                            <select onChange={(e) => setSourceType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Website</option>
                                                <option>Tradeshow</option>
                                                <option>Walkins</option>
                                                <option>Social Media</option>
                                                <option>Relatives</option>
                                                <option>Freinds</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Source type
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setSalesAxecutive(e.target.value)}
                                            type="text"
                                            value={sessionStorage.getItem('user_name')}
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Executive
                                        </Form.Control.Feedback>
                                    </Form.Group> */}
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "74rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <center><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></center>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating lead details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    {/* <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Name</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setName(e.target.value)}

                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                    </Form.Group> */}

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>D.O.B</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setDob(e.target.value)}
                                            value={dob}
                                            type="Date"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Date of Birth
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Age</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAge(e.target.value)}
                                            type="number"
                                            value={age}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Age
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Area</Typography>
                                        <Form.Control
                                            required
                                            value={area}
                                            onChange={(e) => setArea(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Area
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Street</Typography>
                                        <Form.Control
                                            required
                                            value={street}
                                            onChange={(e) => setStreet(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Address</Typography>
                                        <Form.Control
                                            required
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Address
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Pincode</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setPinCode(e.target.value)}
                                            type="number"
                                            value={pinCode}

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Pincode
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Employment Type</Typography>
                                            <select onChange={(e) => setEmpType(e.target.value)} value={empType} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Business</option>
                                                <option>Govt job</option>
                                                <option>Private Job</option>
                                                <option>Professional Job</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Emp type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Finance Type</Typography>
                                            <select onChange={(e) => setFinanceType(e.target.value)} value={financeType} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Own</option>
                                                <option>Loan</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid finance Type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Budget</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBudget(e.target.value)}
                                            type="number"
                                            value={budget}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid budget
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <label style={{ color: 'gray', fontSize: 13 }}>Interested *</label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        value="Yes"
                                                        onChange={(e) => {
                                                            setIntrest(e.target.value);
                                                            setLater('');
                                                        }}
                                                        checked={Intrest === 'Yes'}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        value="No"
                                                        onChange={() => setIntrest('')}
                                                        checked={Intrest === 'No'}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Later"
                                                        name="intrest"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        value="Later"
                                                        onChange={(e) => setLater(e.target.value)}
                                                        checked={Intrest === 'Later'}
                                                    />
                                                </div>
                                            ))}
                                            <Form.Control.Feedback type="invalid">
                                                Please select Interest option
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {(Intrest === 'Yes' || Intrest === 'Later') && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>{Intrest === 'Yes' ? 'Site Visit' : 'Later'}</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setSiteVisit(e.target.value)}
                                                    type={Intrest === 'Yes' ? 'datetime-local' : 'date'}
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid {Intrest === 'Yes' ? 'Site Visit' : 'Date'}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </>


                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Remarks *</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setRemarks(e.target.value)}
                                            type="text"
                                            value={remarks}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Remarks
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen3(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            {/* <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "74rem" }} >
                            <Form noValidate >
                                <div className='mb-2'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='100px' /></Typography>
                                    <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating lead details will receive a privacy audit.</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>User Name</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile Number</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setMobile(e.target.value)}
                                            value={mobile}

                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Mobile Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Email</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setEail(e.target.value)}
                                            type="text"
                                            value={email}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter email
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Age</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAge(e.target.value)}
                                            type="text"
                                            value={age}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid age
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Budget</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBudget(e.target.value)}
                                            type="text"
                                            value={budget}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid budget
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="2" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Time</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setLeadTime(e.target.value)}
                                            type="time"
                                            value={leadTime}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid time
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Date</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setLeadDate(e.target.value)}
                                            type="date"
                                            value={leadDate}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid time
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Street</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setStreet(e.target.value)}
                                            type="text"
                                            value={street}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Area</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setArea(e.target.value)}
                                            type="text"
                                            value={area}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid street
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Address</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text"
                                            value={address}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid address
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>DOB</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setDob(e.target.value)}
                                            type="date"
                                            value={dob}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid dob
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Job Title</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setJobTitle(e.target.value)}
                                            type="text"
                                            value={jobTitle}
                                            style={{ padding: '0.42rem', marginBottom: 15 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid job title
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>State</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setState(e.target.value)}
                                            type="text"
                                            value={state}
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter state
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Pin Code</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setPinCode(e.target.value)}
                                            type="text"
                                            value={pinCode}
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid pin Code
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <div class="radio-wrapper">
                                            <div className="radio-container d-flex" style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>GENDER</Typography>
                                                <div style={{ marginRight: '5rem', marginLeft: 30 }}>
                                                    <input onChange={(e) => setGen(e.target.value)} type="radio" name="gender" value="Male" id="male" />
                                                    <label for="male">MALE</label>
                                                </div>
                                                <div>
                                                    <input onChange={(e) => setGen(e.target.value)} type="radio" name="gender" value="Female" />
                                                    <label for="female">FEMALE</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Select Emp Type</Typography>
                                            <select onChange={(e) => setEmpType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Business</option>
                                                <option>Govt job</option>
                                                <option>Private Job</option>
                                                <option>Professional Job</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Select Lead Status</Typography>
                                            <select onChange={(e) => setLeadStatus(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>New</option>
                                                <option>Contact-Made</option>
                                                <option>Followedup</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Product Interest</Typography>
                                            <select onChange={(e) => setIntrest(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Yes</option>
                                                <option>No</option>

                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Finance Type</Typography>
                                            <select onChange={(e) => setFinanceType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Own</option>
                                                <option>Loan</option>

                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Industry</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setIndustry(e.target.value)}
                                            type="text"
                                            value={industry}
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid industry
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Source Type</Typography>
                                            <select onChange={(e) => setSourceType(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>Website</option>
                                                <option>Tradeshow</option>
                                                <option>Walkins</option>
                                                <option>Social Media</option>
                                                <option>Relatives</option>
                                                <option>Freinds</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Source type
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setSalesAxecutive(e.target.value)}
                                            type="text"
                                            value={salesAxecutive}
                                            style={{ padding: '0.42rem', marginBottom: 16 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Executive
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => {
                                            editAlert()
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Edit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen3(false)
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div> */}

        </div>
    );
}