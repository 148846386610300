import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FaRupeeSign, FaSignOutAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import HRating from './Rating/Rating';
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

export default function BasicGrid() {

    const [instalment, setInstalment] = React.useState()

    const [customer, setCustomer] = React.useState([])
    const [leadCustomer, setLeadCustomer] = React.useState([])

    const customerData = () => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/one-customer', {
            email: sessionStorage.getItem('customerEmail')
        }).then((res) => {
            setCustomer(res.data)
        })
    }

    const customerLeadData = () => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/leadone-customer', {
            email: sessionStorage.getItem('customerEmail')
        }).then((res) => {
            setLeadCustomer(res.data)
        })
    }

    React.useEffect(() => {
        customerLeadData()
        customerData()
    }, [])


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (inst) => {
        setInstalment(inst)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>

            <Grid container style={{ padding: 36 }} spacing={2}>
                <Grid item xs={12}>
                    <div className='card shadow ps-3 pe-3' style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', border: 'none', borderRadius: '0.2rem', backgroundColor: 'white', height: '4rem' }} >
                        <Typography style={{ fontSize: '1.3rem', color: 'rgb(126, 110, 228)' }} >Customer Dashboard / Profile</Typography>
                        <button style={{ height: '2.2rem', borderRadius: '0.2rem', width: '7rem', border: 'none', color: 'white', backgroundColor: 'red' }} onClick={() => {
                            window.location.reload()
                            sessionStorage.removeItem('customer')
                            sessionStorage.removeItem('login')
                            window.location.href = '/'
                        }} ><Typography style={{ colo: 'white', textAlign: 'center' }} ><FaSignOutAlt style={{ fontSize: 15, marginTop: '-0.12rem' }} /> Logout</Typography></button>
                    </div>
                    <div className='customerContainer' style={{ borderRadius: '0.4rem' }} >
                        <Item elevation={0} >
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <div className='customerContainer' >
                                        <Item style={{ height: '105vh', marginTop: 10, overflow: 'auto' }} elevation={0}   >
                                            <center>
                                                <img style={{ width: '130px' }} src='https://img.freepik.com/premium-vector/social-avatar-stories-gradient-frame_41737-3.jpg?size=626&ext=jpg&ga=GA1.2.728366519.1687599894&semt=ais' />
                                                <Typography>Sonu Kunar</Typography>
                                            </center>
                                            <Typography style={{ fontSize: '1.5rem', borderBottom: '5px solid rgb(126, 110, 228)', borderRadius: '0.3rem', textAlign: 'center' }}>Personal Details</Typography>
                                            <br />
                                            <br />

                                            {
                                                leadCustomer.map((data) => {
                                                    return (
                                                        <div>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }} >Email: {data.email}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Age: {data.age} years</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>DOB: {data.dob}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Mobile Number: +91{data.mobile}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Address: {data.address}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Gender: {data.gender}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Zip Code: {data.pinCode}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>State: {data.state}</Typography>
                                                            <Typography style={{ padding: 13, borderRadius: '0.03rem', borderLeft: '2px solid rgb(126, 110, 228)', marginBottom: 7, backgroundColor: 'rgb(246, 244, 255)' }}>Area: {data.area}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Item>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div >
                                        <Item style={{ height: '116vh' }} elevation={0} >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div className='customerContainer'>
                                                        <Item style={{ height: '58vh', overflow: 'hidden' }} elevation={0}>
                                                            <center> <img src='https://media.istockphoto.com/id/1158053848/photo/modern-interior-design-isolated-floor-plan-with-white-walls-blueprint-of-apartment-house.jpg?s=612x612&w=0&k=20&c=w41RLsMlOhN74fzh6dgAGvPcQIPRCUOfwsSZcZKQB4o=' width='92%' /></center>
                                                        </Item>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <div className='customerContainer'>
                                                        {
                                                            customer.map((data) => {
                                                                return (
                                                                    <Item style={{ height: '53.7vh', backgroundColor: 'rgb(126, 110, 228)', overflow: 'auto' }} elevation={0}>
                                                                        <Typography style={{ color: 'white', fontSize: '1.3rem', fontWeight: 'bold', textAlign: 'center', borderBottom: '4px solid white', marginBottom: 14 }} >Flat Details</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }} >Project Name: {data.projectName}</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }}>Block: {data.block}</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }}>Floor: {data.floor}</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }}>Flat Size: {data.flatSize}</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }}>Flat Type: 2 BHK</Typography>
                                                                        <Typography style={{ padding: 10.3, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }}>Flat No: {data.unitNo}</Typography>
                                                                    </Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {
                                                        customer.map((data) => {

                                                            let color = ''
                                                            let widths = ''

                                                            if (data.lead === 'Not Customer') {
                                                                color = 'red'
                                                                widths = false
                                                            }

                                                            if (data.lead === 'Customer') {
                                                                color = 'green'
                                                                widths = true
                                                            }


                                                            let downPayment = ''
                                                            let inst1 = ''
                                                            let inst2 = ''
                                                            let inst3 = ''

                                                            downPayment = data.budget * (30 / 100)
                                                            inst1 = data.budget * (20 / 100)
                                                            inst2 = data.budget * (20 / 100)
                                                            inst3 = data.budget * (30 / 100)

                                                            return (
                                                                <div className='customerContainer'>
                                                                    <Item style={{ height: '53.7vh', backgroundColor: 'rgb(126, 110, 228)', overflow: 'auto' }} elevation={0}>
                                                                        <Typography style={{ color: 'white', fontSize: '1.3rem', fontWeight: 'bold', textAlign: 'center', borderBottom: '4px solid white', marginBottom: 14 }} >Pricing Details</Typography>
                                                                        <Typography style={{ padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem' }} >Total Amount: <FaRupeeSign />{data.budget} /-</Typography>
                                                                        <Typography style={{ color: 'white', fontSize: '1.1rem', fontWeight: 'bold', textAlign: 'center', borderBottom: '2px solid white', marginBottom: 14 }} >Instalments</Typography>
                                                                        <Typography style={{ padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><span>Down Payment: <FaRupeeSign />{downPayment} /-</span>  <span style={{ backgroundColor: 'white', paddingLeft: 11, paddingRight: 11, borderRadius: '1rem', color: 'green', cursor: 'pointer' }} >Success</span></Typography>
                                                                        <Typography style={{ padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><span>1st Instalment: <FaRupeeSign />{inst1} /-</span>  <span style={{ backgroundColor: 'white', paddingLeft: 11, paddingRight: 11, borderRadius: '1rem', color: 'rgb(126, 110, 228)', cursor: 'pointer' }} onClick={() => handleClickOpen(inst1)} >Pay</span></Typography>
                                                                        <Typography style={{ padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><span>2nd Instalment: <FaRupeeSign />{inst2} /-</span>  <span style={{ backgroundColor: 'white', paddingLeft: 11, paddingRight: 11, borderRadius: '1rem', color: 'orange', cursor: 'pointer' }} >Progress</span></Typography>
                                                                        <Typography style={{ padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'rgb(138, 123, 233)', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><span>3rd Instalment: <FaRupeeSign />{inst3} /-</span>  <span style={{ backgroundColor: 'white', paddingLeft: 11, paddingRight: 11, borderRadius: '1rem', color: 'orange', cursor: 'pointer' }} >Progress</span></Typography>
                                                                    </Item>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='customerContainer'>
                                        <Item elevation={0} style={{ height: '21.7rem', background: 'rgb(138, 123, 233)' }} >
                                            <Typography style={{ color: 'white', fontSize: '1.3rem', marginBottom: 10 }} >Contact Service</Typography>
                                            <select style={{ width: '100%', background: 'white', borderRadius: '0.2rem', height: '2.4rem', border: 'none', outline: 'none', paddingLeft: 10, paddingRight: 20 }} >
                                                <option selected value="" disabled >Select One</option>
                                                <option>Engineering Department</option>
                                                <option>Maintainance Department</option>
                                                <option>Accounts Department</option>
                                                <option>Property Department</option>
                                            </select>
                                            <br />
                                            <br />
                                            <textarea rows={8} style={{ width: '100%', background: 'white', borderRadius: '0.2rem', border: 'none', outline: 'none', paddingLeft: 10, paddingRight: 20 }} placeholder='Enter message' />
                                            <br />
                                            <br />
                                            <button style={{ background: 'white', borderRadius: '0.2rem', height: '2.4rem', border: 'none', width: '9rem' }} ><Typography style={{ textAlign: 'center' }} >Submit</Typography></button>
                                        </Item>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>

                                    <div className='customerContainer'>

                                        <Item style={{ height: '21.7rem' }} elevation={0}>
                                            <Typography style={{ color: 'rgb(138, 123, 233)', fontSize: '1.3rem', marginBottom: 10 }} >Feadback</Typography>
                                            <HRating />
                                            <br />
                                            <br />
                                            <textarea rows={7} style={{ width: '100%', background: 'white', borderRadius: '0.2rem', border: '1px solid rgb(126, 110, 228)', outline: 'none', paddingLeft: 10, paddingRight: 20, }} placeholder='Enter message' />
                                            <br />
                                            <br />
                                            <button style={{ background: 'rgb(126, 110, 228)', borderRadius: '0.2rem', height: '2.4rem', border: 'none', width: '9rem', color: 'white' }} ><Typography style={{ textAlign: 'center' }} >Submit</Typography></button>
                                        </Item>
                                    </div>
                                </Grid>
                            </Grid>
                        </Item>
                    </div>
                </Grid>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Instalment First "}</DialogTitle>
                    <DialogContent style={{ width: '32rem' }} >
                        <div style={{ width: '100%', background: 'red' }} >
                            <Typography style={{ textAlign: 'center', color: 'white', paddingTop: 5, paddingBottom: 5 }} >Pay Your Instalment</Typography>
                        </div>
                        <br />
                        <Typography style={{ fontSize: '1.2rem' }} >Amount: <FaRupeeSign style={{ fontSize: 17, marginTop: '-0.1rem' }} />{instalment} /-</Typography>
                    </DialogContent>
                    <DialogActions>
                        <div className='d-flex' >
                            <button className='me-1' onClick={handleClose} style={{ height: '2.2rem', borderRadius: '0.2rem', backgroundColor: 'red', width: '7rem', border: 'none', color: 'white' }}><Typography style={{ colo: 'white', textAlign: 'center' }} >Cancel</Typography></button>
                            <button onClick={handleClose} style={{ height: '2.2rem', borderRadius: '0.2rem', width: '7rem', border: 'none', color: 'white', backgroundColor: 'rgb(126, 110, 228)' }} ><Typography style={{ colo: 'white', textAlign: 'center' }} >Pay</Typography></button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}