import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography, colors } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import MuiAlert from "@material-ui/lab/Alert";
import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';


function SiteVisit() {

    const [leadData, setLeadData] = useState([]);


    const [pname, setPName] = useState('')
    const [Pemail, setPEmail] = useState('')
    const [pmobile, setPMobile] = useState('')
    const [plocation, setPLocation] = useState('')
    const [psourcetype, setPSourceType] = useState('')
    const [preference, setPReference] = useState('')
    const [pchannelpartner, setPchannelPartner] = useState('')
    const [pexecutive, setPExecutive] = useState('')


    const [occupation, setOccupation] = useState()
    const [apartsize, setApartSize] = useState()
    const [apartfacing, setApartFacing] = useState()
    const [budget, setBudget] = useState()
    const [Corner, setCorner] = useState()



    const [flatDetails, setFlatDetails] = useState('');
    const [underConstruction, setUnderConstruction] = useState('');
    const [readyToMove, setReadyToMove] = useState('');

    const handleFlatDetailsChange = (e) => {
        const selectedFlatDetails = e.target.value;
        setFlatDetails(selectedFlatDetails);
        // Reset the fields when changing the selected flat details
        setUnderConstruction('');
        setReadyToMove('');
    };



    const [isVeiw, setIsVeiw] = useState()
    const [response, setResponse] = useState()



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const [open, setOpen] = useState(false)


    const [Validated, setValidated] = useState(false);

    const [open1, setOpen1] = useState(false)

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_sitevisit()
        }

        setValidated(true);
    };

    const [res, setRes] = React.useState([])

    const getLeadname = () => {
        axios.get('https://buildfastvapi.emedha.in/api/v1/lead-data').then((res) => {
            setRes(res.data.data)
            // console.log("leaddata",res.data.data);
            // setIsFilter(true)
        })
    }

    React.useEffect(() => {
        getLeadname()
        customerObjsi()
        get_tyunz()
    },[])

    const[nname, setNname] = useState('')
    const[nmobile, setNmobile] = useState('')
    const[nemail, setNemail ] = useState('')
    const[nst, setNst ] = useState('')
    const[leadloc, setLeadloc] = useState('')
    const[nrefname, setNrefname] = useState('')
    const[nrefmob, setNrefmob] = useState('')
    const[ncp, setNcp] = useState('')
    const[ncpname, setNcpname] = useState('')
    const[ncpid, setNcpid] = useState('')
    const[ncb, setNcb] = useState('')


    const getdetls = (value) => {
        
        axios.post('https://buildfastvapi.emedha.in/api/v1/get-lead-details', {
            value
        })
            .then(res => {
                // setNid(res.data.Id)
                console.log(res.data.Location);
                setNname(res.data.name)
                setNmobile(res.data.mobile)
                setNemail(res.data.email)
                setNst(res.data.sourceType)
                setLeadloc(res.data.location)
                setNrefname(res.data.refName)
                setNrefmob(res.data.refMobile)
                setNcp(res.data.channelPartner)
                setNcpname(res.data.cpName)
                setNcpid(res.data.cpId)
                setNcb(res.data.commentBox)

            })
    }

    const add_sitevisit = () => {
        console.log( {
            name:nname, mobile:nmobile, email:nemail, location:leadloc, source:nst, reference:nrefname, channelPartner:ncp, executive:pexecutive, occupation:occupation, budget:budget, intrstApartmtSize:apartsize, intrtApartmntFacing:apartfacing, corner:Corner, ucCommentBox:underConstruction, readytomove:readyToMove
        });
        axios.post("https://buildfastvapi.emedha.in/create-customer/api/v1/add-sitevistfrm", {
            name:nname, mobile:nmobile, email:nemail, location:leadloc, source:nst, reference:nrefname, channelPartner:ncp, executive:pexecutive, occupation:occupation, budget:budget, intrstApartmtSize:apartsize, intrtApartmntFacing:apartfacing, corner:Corner, ucCommentBox:underConstruction, readytomove:readyToMove
        }).then((res) => {
            if (res.data.status === 200) {
                
                setOpen(false)
                // getlm()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data is added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [customerRessi, setCustomerResponsesi] = React.useState([])


    const customerObjsi = () => {
        axios.get('https://buildfastvapi.emedha.in/create-customer/api/v1/getsitform').then((res) => {
            setCustomerResponsesi(res.data)
        })
    }

    const [typicaluzdrop, setTypicaluzdrop] = useState([])

    const get_tyunz = () => {
        axios.post("https://buildfastvapi.emedha.in/project/api/v1/gettypical-unitsize").then((res) => {
            if (res.data.status === 200) {
                setTypicaluzdrop(res.data.data)
                console.log(res.data.data);

            }
        })
    }
  


    return (
        <div>
            {
                !isVeiw && (
                    <div className='leadManagementTable'>
                        <Paper elevation={0} >

                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>
                                        <input onChange={(e) => leadDataSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    <div>
                                        <Button onClick={handleClickOpen} style={{ width: '13rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>Site Visit</Button>
                                    </div>
                                </div>
                                <Table style={{ position: 'relative', top: '1.3rem' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                                            >
                                                Name
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                Mobile
                                            </TableCell>

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Occupation
                                            </TableCell>
                                           
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Budget
                                            </TableCell>




                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', width: 18 }}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {customerRessi && customerRessi
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }

                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.name}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{row.mobile}</button>
                                                        </TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.occupation}</TableCell>
                                                       
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.budget}</TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                            <div style={{ display: 'flex' }} >
                                                                <div>
                                                                    <IconButton
                                                                        id="fade-button"
                                                                        aria-controls={open2 ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open2 ? 'true' : undefined}
                                                                        onClick={(e) => {
                                                                            handleClick(e.currentTarget)
                                                                            setId(row.Id)
                                                                        }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="fade-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'fade-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open2}
                                                                        onClose={handleClose2}
                                                                        TransitionComponent={Fade}
                                                                    >
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setIsVeiw(true)
                                                                                }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem>
                                                                            )

                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setOpen3(true)

                                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                                                            )
                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => deleteAlert(row.Id)}><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                            )
                                                                        }
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    customerRessi.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>

                    </div>
                )
            }
            {
                isVeiw && (
                    <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative', }} >
                        <FaWindowClose onClick={() => {
                            setIsVeiw(false)
                            leadDataGet()
                            setIsFilter(false)
                            setEmpType('Business')
                            setLeadStatus('New')
                        }} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} ></FaWindowClose>
                        <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }} ><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></Typography>
                            <Typography>Lead customers information details</Typography>
                        </div>
                        <br />
                        <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>

                            <div className='card' style={{ paddingTop: 43, border: '1px solid rgb(126, 110, 228)', borderRadius: '0.3rem', padding: 11, flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Name:</span></Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>DOB:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Age:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Area:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Address:</span> </Typography>
                                </div>

                            </div>

                        </div>
                    </div>
                )
            }
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "74rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <center><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></center>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Site Visit</Typography>

                                </div>
                                <Row className="mb-3">

                                    <Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <div style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Name</Typography>
                                                <select onChange={(e) => getdetls(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                    <option>--Select--</option>
                                                    {
                                                        res.map((item => (
                                                            <option>{item.name},{item.mobile}</option>
                                                        )))
                                                    }
                                                </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Source type
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Name *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nname}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile </Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nmobile}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Mobile
                                            </Form.Control.Feedback>
                                        </Form.Group> 

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Email </Typography>
                                            <Form.Control
                                                value={nemail}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid email
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Location </Typography>
                                            <Form.Control
                                                value={leadloc}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Location
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Source Type</Typography>
                                            <Form.Control

                                                disabled
                                                value={nst}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid Source Type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {
                                            nst === 'Reference' && (
                                                <>
                                                <Form.Group as={Col} md="4">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Refernce Name *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nrefname}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            
                                        </Form.Group>
                                                <Form.Group as={Col} md="4">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Refernce Mobile *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nrefmob}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            
                                        </Form.Group>
                                               
                                                </>
                                            )
                                        }



                                            {/* <Form.Group as={Col} md="4" controlId="validationCustom03">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Reference</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setWhatsappMobile(e.target.value)}
                                                    type="text"
                                                    value={preference}
                                                    disabled
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Reference
                                                </Form.Control.Feedback>
                                            </Form.Group> */}

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Channel Partner</Typography>
                                            <Form.Control
                                                required
                                                value={ncp}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Channel Partner
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {

                                            ncp === 'Yes' && (
                                                <>
                                                <Form.Group as={Col} md="4">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Cp Name *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={ncpname}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            
                                        </Form.Group>
                                                <Form.Group as={Col} md="4">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Cp Id *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={ncpid}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            
                                        </Form.Group>
                                        </>
                                            )
                                        }

                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Executive</Typography>
                                            <Form.Control
                                                value={pexecutive}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                onChange={(e) =>setPExecutive(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid Executive
                                            </Form.Control.Feedback>
                                        </Form.Group>



                                    </Row>


                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Occupation</Typography>
                                            <Form.Select onChange={(e) => setOccupation(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Private</option>
                                                <option>Public</option>
                                                <option>Self Employed</option>
                                                <option>Business</option>
                                                <option>Others</option>
                                            </Form.Select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select valid Occupation option
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Budget</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBudget(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Budget
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Interested Apartment Size</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setApartSize(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>select Apartment Size</option>

                                            {typicaluzdrop.map((data, index) => (
                                             <option key={index}>{`${data.unitsize}`}</option>
                                             ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Interested Apartment Size
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Interested Apartment Facing</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setApartFacing(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>select Facing</option>
                                            <option>East</option>
                                            <option>West</option>
                                            <option>North</option>
                                            <option>South</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Interested Apartment Facing
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ marginBottom: 14 }}>
                                            <Typography style={{ color: 'gray', fontSize: 13 }}> Corner</Typography>
                                            <Form.Select
                                                onChange={(e) => setCorner(e.target.value)}
                                                style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                <option>--select--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select valid Corner option
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <>
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <div style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Flat Details</Typography>
                                                <select
                                                    onChange={handleFlatDetailsChange}
                                                    style={{
                                                        height: '2.3rem',
                                                        color: 'gray',
                                                        borderRadius: '0.3rem',
                                                        outline: 'none',
                                                        width: '100%',
                                                        border: '0.7px solid lightgray',
                                                    }}
                                                >
                                                    <option>--select--</option>
                                                    <option>Under Construction</option>
                                                    <option>Ready To Move</option>
                                                </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Flat Details
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {flatDetails === 'Under Construction' && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Under Construction</Typography>
                                                <Form.Control
                                                    required
                                                    value={underConstruction}
                                                    onChange={(e) => setUnderConstruction(e.target.value)}
                                                    type="text"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Under Construction
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}

                                        {flatDetails === 'Ready To Move' && (
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Ready To Move</Typography>
                                                <Form.Control
                                                    required
                                                    value={readyToMove}
                                                    onChange={(e) => setReadyToMove(e.target.value)}
                                                    type="date"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                            </Form.Group>
                                        )}
                                    </>

                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen(false)
                                            setValidated(false)
                                            }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default SiteVisit
