import React, { useEffect, useState } from 'react'
import './Dashboardhome.css'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


import { Navbar, Container, Nav, NavDropdown,Dropdown } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BsFillArchiveFill } from 'react-icons/bs';

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  TableBody,
  TablePagination,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
 

} from "@mui/material";



// const EditPricingModal = ({ open, unit, onClose,onSave }) => {
//   const [pricingData, setPricingData] = useState({
//     basePrice: unit.basePrice || '',
//     waterElectricityAmt: unit.waterElectricityAmt || '',
//     facingAmount: unit.facingAmount || '',
//     clubHouseAmt: unit.clubHouseAmt || '',
//     cautionRefAmt: unit.cautionRefAmt || '',
//     cautionNonRefAmt: unit.cautionNonRefAmt || '',
//     carpousAmt: unit.carpousAmt || '',
//     cornerAmount:unit.cornerAmount||'',
//     advMaitanceAmt: unit.advMaitanceAmt || '',
//     parkviewAmount: unit.parkviewAmount || ''
//   });


//   useEffect(() => {
//     if (unit) {
//       setPricingData({
//         basePrice: unit.basePrice || '',
//         waterElectricityAmt: unit.waterElectricityAmt || '',
//         facingAmount: unit.facingAmount || '',
//         clubHouseAmt: unit.clubHouseAmt || '',
//         cautionRefAmt: unit.cautionRefAmt || '',
//         cautionNonRefAmt: unit.cautionNonRefAmt || '',
//         carpousAmt: unit.carpousAmt || '',
//         cornerAmount: unit.cornerAmount || '',
//         advMaitanceAmt: unit.advMaitanceAmt || '',
//         parkviewAmount: unit.parkviewAmount || ''
//       });
//     }
//   }, [unit]);

//   const handleChange = (e) => {
//     setPricingData({
//       ...pricingData,
//       [e.target.name]: e.target.value
//     });
//   };

//   const handleSave = () => {
//     onSave(pricingData);
//   };




//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle style={{color:'blue',fontWeight:'600'}}>Edit Pricing Data</DialogTitle>
//       <DialogContent>
//         {Object.keys(pricingData).map(key => (
//           <TextField
//             key={key}
//             label={key}
//             name={key}
//             value={pricingData[key]}
//             onChange={handleChange}
//             margin="normal"
//             fullWidth
//           />
//         ))}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSave}>Save</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };


const EditPricingModal = ({ open, unit, onClose, onSave }) => {
  const [pricingData, setPricingData] = useState({
    basePrice: unit.basePrice || '',
    waterElectricityAmt: unit.waterElectricityAmt || '',
    facingAmount: unit.facingAmount || '',
    clubHouseAmt: unit.clubHouseAmt || '',
    cautionRefAmt: unit.cautionRefAmt || '',
    cautionNonRefAmt: unit.cautionNonRefAmt || '',
    carpousAmt: unit.carpousAmt || '',
    cornerAmount: unit.cornerAmount || '',
    advMaitanceAmt: unit.advMaitanceAmt || '',
    parkviewAmount: unit.parkviewAmount || '',
    onecarparkAmt: unit.onecarparkAmt || '',
    twocarparkAmt: unit.twocarparkAmt || '',
    threecarparkAmt: unit.threecarparkAmt || ''
  });

  useEffect(() => {
    if (unit) {
      setPricingData({
        basePrice: unit.basePrice || '',
        waterElectricityAmt: unit.waterElectricityAmt || '',
        facingAmount: unit.facingAmount || '',
        clubHouseAmt: unit.clubHouseAmt || '',
        cautionRefAmt: unit.cautionRefAmt || '',
        cautionNonRefAmt: unit.cautionNonRefAmt || '',
        carpousAmt: unit.carpousAmt || '',
        cornerAmount: unit.cornerAmount || '',
        advMaitanceAmt: unit.advMaitanceAmt || '',
        parkviewAmount: unit.parkviewAmount || '',
        onecarparkAmt: unit.onecarparkAmt || '',
        twocarparkAmt: unit.twocarparkAmt || '',
        threecarparkAmt: unit.threecarparkAmt || ''
      });
    }
  }, [unit]);

  const handleChange = (e) => {
    setPricingData({
      ...pricingData,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = () => {
    onSave(pricingData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ color: 'blue', fontWeight: '600' }}>Edit Pricing Data</DialogTitle>
      <DialogContent>
        {Object.keys(pricingData).map(key => (
          <TextField
            key={key}
            label={key}
            name={key}
            value={pricingData[key]}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};




const ActionPopup = ({ open, onClose, onEditPricing, onApplyDiscount }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Actions</DialogTitle>
    <DialogContent>
      <Button onClick={onEditPricing} color="primary">Edit Pricing</Button>
      <Button onClick={() => onApplyDiscount()} color="secondary">Apply Discount</Button>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const DiscountModal = ({ open, onClose, onApply }) => {
  const [discount, setDiscount] = useState('');

  const handleChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleApply = () => {
    if (discount) {
      onApply(parseFloat(discount));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Apply Discount</DialogTitle>
      <DialogContent>
        <TextField
          label="Discount Percentage"
          type="number"
          value={discount}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApply} color="primary">Apply</Button>
      </DialogActions>
    </Dialog>
  );
};






function DashBoardHomePage() {

const [avl, setAvl] = useState(0)
const [bok, setBok] = useState(0)
const [boked, setBoked] = useState(0)
const [mort, setMort] = useState(0)
const [selectedUnit, setSelectedUnit] = useState(null);
const [open, setOpen] = useState(false);


const [openActionPopup, setOpenActionPopup] = useState(false);

const [openEditPricingModal, setOpenEditPricingModal] = useState(false);
const [openDiscountModal, setOpenDiscountModal] = useState(false);



const handleEditPricing = () => {
  setOpenActionPopup(false);
  setOpenEditPricingModal(true);
};

const handleApplyDiscount = async (discountPercentage) => {
  try {
    const response = await fetch(`https://buildfastapi.kollagroup.in/project/api/v1/api/apply-discount/${selectedUnit.unit_name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ discountPercentage }),
    });

    if (!response.ok) {
      throw new Error('Failed to apply discount');
    }

    const result = await response.json();
    console.log(result.message);
    
    // Refresh or update your state to reflect the changes
    // Example: fetchUnitsData(); // if you have a function to reload unit data

    setOpenDiscountModal(false);
    setOpenActionPopup(false);
  } catch (error) {
    console.error('Error applying discount:', error);
  }
};
;



const handleClickTotalValue = (unit) => {
  setSelectedUnit(unit);
  setOpenActionPopup(true);
};

console.log(selectedUnit)

// https://buildfastapi.kollagroup.in/project/api/v1/api/update-pricing/${selectedUnit.unit_name}

const handleSave = async (updatedData) => {
  try {
    // Make API call to update pricing data
    await fetch(`https://buildfastapi.kollagroup.in/project/api/v1/api/update-pricing/${selectedUnit.unit_name}`, { // Use unitName instead of unitId
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    });

    // Update local state or refetch data
    setOpen(false);
    // Optionally refetch data here or update the local state to reflect changes
  } catch (error) {
    console.error('Failed to update pricing data:', error);
  }
};


  const data = [
    { name: 'Group A', value: avl },
    { name: 'Group B', value: boked },
    { name: 'Group C', value: bok },
    { name: 'Group D', value: mort },
];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'gray'];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const [flagCount, setFlagCount] = React.useState(0);
  const [flagNotOneCount, setFlagNotOneCount] = React.useState(0);

  React.useEffect(() => {
    const getUnit = () => {
      axios.post("https://buildfastapi.kollagroup.in/project/api/v1/unit-show")
        .then((response) => {
          let newFlagOneCount = 0;
          let newFlagNotOneCount = 0;
          response.data.forEach((item) => {
            if (item.flag === 1) {
              newFlagOneCount++;
            } else {
              newFlagNotOneCount++;
            }
          });

          setFlagCount(newFlagOneCount);
          setFlagNotOneCount(newFlagNotOneCount);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    get_basic()
    get_common()
    // get_tower()
    get_floor()

  }, []);

  
  

  const get_basic = () => {
    axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-basic").then((res) => {
        if (res.data.status === 200) {
          setProjectOptions(res.data.data)
          
        }
    })
}






    const get_common = () => {
      axios.get("https://buildfastapi.kollagroup.in/project/api/v1/get-blocks").then((res) => {
          if (res.data.status === 200) {
            setBlockOptions(res.data.data)
            
          }
      })
  }

//   const get_tower = () => {
//     axios.post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall").then((res) => {
//         if (res.data.status === 200) {
//           setTowerOptions(res.data.data)
            
//         }
//     })
// }

const get_floor = () => {
  axios.get("https://buildfastapi.kollagroup.in/project/api/v1/get-floor").then((res) => {
      if (res.data.status === 200) {
        setFloorOptions(res.data.data)
      }
  })
}



const getdetails = (text) => {

  if (selectedOption === 'project') {
    setSelectedProject(text); 
    console.log(text)// Store the selected project name
  }

  console.log("t",text);
  axios.post("https://buildfastapi.kollagroup.in/project/api/v1/search-dash",{text}).then((res) => {
   
      setAvl(res.data.totalCount);
      setBok(res.data.countFlag0);
      setBoked(res.data.countFlag1);
      setMort(res.data.countFlag2);
    
})
}

  

  const [selectedOption, setSelectedOption] = useState('');
  const [projectOptions, setProjectOptions] = useState([]);
  const [blockOptions, setBlockOptions] = useState([]);
  const [towerOptions, setTowerOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setSelectedProject(value)
    getLeadsData(value)
    // setBlockOptions(value);
    // setTowerOptions([]);
    // setFloorOptions([]);
  
  };

  const [selectedProject, setSelectedProject] = useState('');
  console.log(selectedProject)

  const [unitCount, setUnitCount] = useState(0);

  useEffect(() => {
    if (selectedProject) {
      axios.get(`https://buildfastapi.kollagroup.in/project/api/v1/api/unit-count`, {
        params: { projectName: selectedProject }
      })
      .then(response => {
        setUnitCount(response.data.unitCount);
        console.log(response.data.unitCount)
      })
      .catch(error => {
        console.error('There was an error fetching the unit count!', error);
      });
    }
  }, [selectedProject]);


  const [recordCount, setRecordCount] = useState(0);

  useEffect(() => {
    if (selectedProject) {
      axios.get(`https://buildfastapi.kollagroup.in/project/api/v1/api/project-count`, {
        params: { projectName: selectedProject }
      })
      .then(response => {
        setRecordCount(response.data.recordCount);
      })
      .catch(error => {
        console.error('There was an error fetching the record count!', error);
      });
    }
  }, [selectedProject]);


  const [leadCount, setLeadCount] = useState(0);

  useEffect(() => {
    if (selectedProject) {
      axios.get('https://buildfastapi.kollagroup.in/project/api/v1/api/leads-count', {
        params: { projectName: selectedProject }
      })
      .then(response => {
        setLeadCount(response.data.leadCount);
      })
      .catch(error => {
        console.error('There was an error fetching the lead count!', error);
      });
    }
  }, [selectedProject]);


  const availableUnits=unitCount-recordCount


  const [unitsData, setUnitsData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [tableState,setTableState]=useState(false)

  const [tableStateA,setTableStateA]=useState(false)

  const [tableStateB,setTableStateB]=useState(false)

  // const getUnitsData = async (selectedProject) => {
  //   console.log('Fetching data for project:', selectedProject); // Debug log
  //   try {
  //     const response = await axios.get('https://buildfastapi.kollagroup.in/project/api/v1/get-units-data', {
  //       params: { projectName: selectedProject },
  //     });
  //     console.log('Data received:', response.data); // Debug log
  //     setUnitsData(response.data.results);
  //   } catch (err) {
  //     console.error('Error fetching data:', err); // Debug log
      
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // https://buildfastapi.kollagroup.in/project/api/v1/get-units-data
  const [ttowerno, setTowardsTowerNumber] = useState("");
  const [selectedFloor, setSelectedFloor] = useState('');

const handleFloorChange =async (e) => {
  const floor = e.target.value;
  setSelectedFloor(floor);

}

// https://buildfastapi.kollagroup.in/project/api/v1/get-units-data

const getUnitsData = async (selectedProject, ttowerno = null, selectedFloor = null) => {
  console.log('Fetching data for project:', selectedProject, 'tower:', ttowerno, 'floor:', selectedFloor); // Debug log

  try {
    // Adjust API call based on whether tower or floor is specified
    const response = await axios.get('https://buildfastapi.kollagroup.in/project/api/v1/get-units-data', {
      params: { 
        projectName: selectedProject, 
        tower: ttowerno, 
        floor: selectedFloor
      },
    });
    console.log('Data received:', response.data); // Debug log
    setUnitsData(response.data.results);
  } catch (err) {
    console.error('Error fetching data:', err); // Debug log
  } finally {
    setLoading(false);
  }
};

// UseEffect to manage when data should be fetched
useEffect(() => {
  if (selectedProject) {
    // Fetch data based on whether tower and/or floor is specified
    if (ttowerno || selectedFloor) {
      // Fetch data for the specific tower, floor, or both
      getUnitsData(selectedProject, ttowerno, selectedFloor);
    } else {
      // Fetch data for the entire project
      getUnitsData(selectedProject);
    }
  }
}, [selectedProject, ttowerno, selectedFloor]); // Dependency array to trigger effect on changes




  
    
  // const [responset, setResponset] = useState([]);
    
  // const get_floor = () => {
  //   axios
  //     .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setResponset(res.data.data);
  //         console.log(res.data.data);
  //       }
  //     });
  // };
  
  // React.useEffect(() => {
  
  //   get_floor();
  
  // }, []);
  

  const [availableUnitsData, setavailableUnitsData] = useState([]);

  const availableUnitsDataCot=availableUnitsData.length

  // const getAvaialbleUnitsData = async (selectedProject) => {
  //   console.log('Fetching data for project:', selectedProject); // Debug log
  //   try {
  //     const response = await axios.get('https://buildfastapi.kollagroup.in/project/api/v1/get-available-units-data', {
  //       params: { projectName: selectedProject },
  //     });
  //     console.log('Data received:', response.data); // Debug log
  //     setavailableUnitsData(response.data.results);
  //   } catch (err) {
  //     console.error('Error fetching data:', err); // Debug log getLeadsData
      
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // https://buildfastapi.kollagroup.in/project/api/v1/get-available-units-data

  // const getAvailableUnitsData = async (selectedProject, ttowerno, selectedFloor) => {
  //   console.log('Fetching available units for project:', selectedProject, 'tower:', ttowerno, 'floor:', selectedFloor); // Debug log
  
  //   try {
  //     const response = await axios.get('https://buildfastapi.kollagroup.in/project/api/v1/get-available-units-data', {
  //       params: { 
  //         projectName: selectedProject, 
  //         tower:ttowerno, 
  //         floor:selectedFloor 
  //       },
  //     });
  //     console.log('Data received:', response.data); // Debug log
  //     setavailableUnitsData(response.data.results);
  //   } catch (err) {
  //     console.error('Error fetching available units:', err); // Debug log
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  // useEffect(() => {
  //   if (selectedProject) {
  //     // Fetch data based on whether tower and/or floor is specified
  //     if (ttowerno || selectedFloor) {
  //       // Fetch data for the specific tower, floor, or both
  //       getAvailableUnitsData(selectedProject, ttowerno, selectedFloor);
  //     } else {
  //       // Fetch data for the entire project
  //       getAvailableUnitsData(selectedProject);
  //     }
  //   }
  // }, [selectedProject, ttowerno, selectedFloor]); // Add tfloor to the dependency array
  //  // Dependency array to trigger effect on changes


  const getAvailableUnitsData = async (selectedProject, ttowerno, selectedFloor) => {
    console.log('Fetching available units for project:', selectedProject, 'tower:', ttowerno, 'floor:', selectedFloor); // Debug log
  
    try {
      const params = { 
        projectName: selectedProject, 
        tower: ttowerno || undefined,  // Set to undefined if not provided
        floor: selectedFloor || undefined // Set to undefined if not provided

        
      };


      // https://buildfastapi.kollagroup.in/project/api/v1/get-available-units-data
  
      // Remove any parameters that are undefined
      Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);
  
      const response = await axios.get('https://buildfastapi.kollagroup.in/project/api/v1/get-available-units-data', { params });
      console.log('Data received:', response.data); // Debug log
      setavailableUnitsData(response.data.results);
    } catch (err) {
      console.error('Error fetching available units:', err); // Debug log
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    if (selectedProject) {
      // Fetch data based on whether tower and/or floor is specified
      if (ttowerno || selectedFloor) {
        // Fetch data for the specific tower, floor, or both
        getAvailableUnitsData(selectedProject, ttowerno, selectedFloor);
      } else {
        // Fetch data for the entire project
        getAvailableUnitsData(selectedProject);
      }
    }
  }, [selectedProject, ttowerno, selectedFloor]); // Dependency array to trigger effect on changes
  


  console.log(availableUnitsData)


  const [leadsData, setleadsData] = useState([]);

  const getLeadsData = async (selectedProject) => {
    console.log('Fetching data for project:', selectedProject); // Debug log
    try {
      const response = await axios.get('https://buildfastvapi.emedha.in/api/v1/get-villa-leads', {
        params: { projectName: selectedProject },
      });
      console.log('Data received:', response.data); // Debug log
      setleadsData(response.data);
    } catch (err) {
      console.error('Error fetching data:', err); // Debug log getLeadsData
      
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {

  //   getAvaialbleUnitsData(selectedProject)
  //   getAvaialbleUnitsData(selectedProject)
  // }, []);


  console.log(unitsData)
  console.log(availableUnitsData)
console.log(leadsData)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const determineStatus = (lead) => {
    if (lead.sitevistTab === 1) {
      return 'Site Visited';
    } else if (lead.callBackTab === 1) {
      return 'Call Back';
    } else if (lead.notIntrstTab === 1) {
      return 'Not Interested';
    } else if (lead.intrestTab === 1) {
      return 'Interested';
    }
    return 'No Status'; // Default if none of the conditions are met
  };




  const [filterInput, setFilterInput] = React.useState('');

const handleFilterInputChange = (event) => {
  setFilterInput(event.target.value);
};

// Filter data based on the input
const filteredUnitsData = unitsData.filter(unit =>
  unit.unit_name.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.Facing.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.cornor.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.unitSize.toLowerCase().includes(filterInput.toLowerCase()) 
 
);

const filteredAvailableUnitsData = availableUnitsData.filter(unit =>
  unit.unit_name.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.Facing.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.cornor.toLowerCase().includes(filterInput.toLowerCase()) ||
  unit.unitSize.toLowerCase().includes(filterInput.toLowerCase()) 

);

const filteredLeadsData = leadsData.filter(lead =>
  lead.name.toLowerCase().includes(filterInput.toLowerCase()) ||
  lead.pretime.toLowerCase().includes(filterInput.toLowerCase()) ||
  lead.salesExecutive.toLowerCase().includes(filterInput.toLowerCase()) ||
  lead.sourceType.toLowerCase().includes(filterInput.toLowerCase()) ||
  determineStatus(lead).toLowerCase().includes(filterInput.toLowerCase())
);



const [response4, setResponse4] = React.useState([]);

console.log(response4)

// https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor

// const get_floors = (ttowerno) => {
//   axios
//     .post(
//       "https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor",
//       {
//         value1: ttowerno,
//       }
//     )
//     .then((res) => {
//       setResponse4(res.data);
//       // setTower(value1);
//       //select_floor();
//     });
// };

// useEffect(() => {
//   get_floors(ttowerno)

// },[ttowerno])

const UnitsCount=unitsData.length
    
const [selectedType, setSelectedType] = useState('');

const [unitdata, setUnitdata] = useState([]);

const get_unit = (projectName) => {
    axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-villa-plots", {
        params: { projectName }
    })
    .then((res) => {
        setUnitdata(res.data.data);
        console.log("Response data:", res.data);
    })
    .catch((error) => {
        console.error("Error fetching units:", error);
    });
};


const projectTypes = [...new Set(projectOptions.map(data => data.projectType))];

// Filter project names based on selected project type projectOptions
const filteredProjectNames = projectOptions.filter(data => data.projectType === selectedType);


  return (
    <main className='main-container'>
   <div className='card mt-2' style={{ border: 'none', padding: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
        <div className='d-flex align-items-center'>
          {/* <Typography style={{ marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> 
           <select onChange={(e) => handleOptionChange(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '16rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
            <option value="">Select Option</option>
            <option value="project">Project</option>
            
          </select>  */}
          {/* {selectedOption === 'project' && ( */}
          <div style={{width:'20rem'}}>
            <Typography style={{ color: 'gray', fontSize: 13 }}>Project Type</Typography>
            <Form.Select
                onChange={(e) => {
                    setSelectedType(e.target.value);
                  // Set the selected project type
                }}
                style={{ padding: '0.42rem', marginBottom: 14,width:'16rem' }}
            >
                <option>Select Project Type</option>
                {projectTypes.map((type) => (
                    <option key={type} value={type}>{type}</option>
                ))}
            </Form.Select>
            </div>
          <div className='d-flex flex-column mb-3'>
          <Typography style={{ color: "gray", fontSize: 13, marginLeft:'1rem' }}>
                        Project{" "}
                      </Typography>
               <select  onChange={(e) => {handleOptionChange(e.target.value);  get_unit(e.target.value) }} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem', marginLeft: '1rem' }}>
              <option value="">Select Project</option>
              {filteredProjectNames.map((option, index) => (
                <option key={index} >{option.ProjectName}</option>
              ))}
            </select>
            </div>
          {/* )} */}
        {/* {selectedType!=="Villa" &&     <Form.Group as={Col} md="3" controlId="validationCustom01" >
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Tower{" "}
                      </Typography>
                      <Form.Select
                        required
                        value={ttowerno}
                        onChange={(e) => setTowardsTowerNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Tower </option>
                        {towerOptions
                          .filter((item) => item.Towers.startsWith(selectedProject)) // Filter towers that start with the project name
                          .map((item) => (
                            <option>{item.Towers}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Tower Number
                      </Form.Control.Feedback>
                    </Form.Group> } */}

                    {selectedType!=="Villa" &&      <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Floor
                    </Typography>
                    <Form.Select
                      required
                    onChange={handleFloorChange}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Floor</option>
                      {[
                        ...new Set(response4.map((data) => data.floor)),
                      ].map((floor, index) => (
                        <option key={index}>{floor}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group>}

          {selectedOption === 'block' && (
            <select onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Block</option>
              {blockOptions.map((option, index) => (
                <option key={index}>{option.Blocks}</option>
              ))}
            </select>
          )}
          {selectedOption === 'tower' && (
            <select onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Tower</option>
              {towerOptions.map((option, index) => (
                <option key={index} >{option.Towers}</option>
              ))}
            </select>
          )}
          {selectedOption === 'floor' && (
            <select  onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Floor</option>
              {floorOptions.map((option, index) => (
                <option key={index} >{option.selectType}</option>
              ))}
            </select>
          )}
        </div>

      </div>


 {/*<div className='main-cards' style={{marginTop:'40px'}}>
                <div onClick={()=>{getUnitsData(selectedProject),setTableState(true),setTableStateA(false),setTableStateB(false)}} className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(168, 158, 230)',color: "white",height:'135px' }}>
                    <div className='card-inner ' >
                        <h3>TOTAL UNITS</h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h6>{UnitsCount}</h6>
                    </div>
                </div>
                <div  onClick={()=>{getAvailableUnitsData(selectedProject),setTableState(false),setTableStateA(true),setTableStateB(false)}} className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(141, 242, 151)',color: "white" }}>
                    <div className='card-inner text-center'>
                        <h3>AVAILABLE UNITS  </h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h6>{availableUnitsDataCot}</h6>

                    </div>
                </div>
                <div onClick={()=>{getLeadsData(selectedProject),setTableState(false),setTableStateA(false),setTableStateB(true)}} className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(250, 170, 178)',color: "white" }}>
                    <div className='card-inner text-center'>
                        <h3>NO OF LEADS </h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h6>{leadCount}</h6>

                    </div>
                    </div>
            </div>*/}
<div className='main-cards' style={{marginTop:'40px'}}>
  <div 
    onClick={() => {
      getUnitsData(selectedProject, ttowerno, selectedFloor); // Pass current tower and floor
      setTableState(true);
      setTableStateA(false);
      setTableStateB(false);
    }} 
    className='card-box d-flex flex-column justify-content-center' 
    style={{backgroundColor:'#8a0404', color: "white", height:'145px' }}
  >
    <div className='card-inner'>
      <h3>TOTAL UNITS</h3>
      <BsFillArchiveFill className="card-icon" />
      <h6>
      <h6>
  {selectedType === "Villa" ? unitdata.length : UnitsCount}
</h6>

</h6>

    </div>
  </div>
  
  <div 
    onClick={() => {
      getUnitsData(selectedProject, ttowerno, selectedFloor); // Pass current tower and floor
      setTableState(false);
      setTableStateA(true);
      setTableStateB(false);
    }} 
    className='card-box d-flex flex-column justify-content-center' 
    style={{backgroundColor:'#d2be79', color: "white" }}
  >
    <div className='card-inner text-center'>
      <h3>AVAILABLE UNITS</h3>
      <BsFillArchiveFill className="card-icon" />
      <h6>
  {selectedType === "Villa" ? unitdata.length : availableUnitsDataCot}
</h6>

    </div>
  </div>
  
  <div 
    onClick={() => {
      getUnitsData(selectedProject, ttowerno, selectedFloor); // Pass current tower and floor
      setTableState(false);
      setTableStateA(false);
      setTableStateB(true);
    }} 
    className='card-box d-flex flex-column justify-content-center' 
    style={{backgroundColor:'#d35050', color: "white" }}
  >
    <div className='card-inner text-center'>
      <h3>NO OF LEADS</h3>
      <BsFillArchiveFill className="card-icon" />
      <h6>{leadsData.length}</h6>
    </div>
  </div>
</div>



            <div>
          <input
            value={filterInput}
            onChange={handleFilterInputChange}
            style={{
              height: '2.3rem',
              color: 'gray',
              borderRadius: '0.3rem',
              outline: 'none',
              width: '23rem',
              border: '0.7px solid lightgray',
            }}
            placeholder="Search...."
          />
        </div>

        {tableState&&
            <div>
      {loading && <Typography variant="h6">Loading...</Typography>}
      {error && <Typography variant="h6" color="error">{error}</Typography>}
      <TableContainer component={Paper} style={{marginTop:'40px'}}>
        <Table>
          <TableHead>
            <TableRow>
            {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa No</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Unit Name</TableCell>)}
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Facing</TableCell>
              {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa Size</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Cornor</TableCell>)}
                {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa Sq.ft</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Unit Size</TableCell>)}
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Total Value</TableCell>
            </TableRow>
          </TableHead>
          {selectedType==="Apartment" &&
          <TableBody>
        
            {filteredUnitsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unit) => (
              <TableRow key={unit.id}>
                <TableCell>{unit.unit_name}</TableCell>
                <TableCell>{unit.Facing}</TableCell>
                <TableCell>{unit.cornor}</TableCell>
                <TableCell>{unit.unitSize}</TableCell>
                <TableCell>{unit.totalvalue}</TableCell>
              </TableRow>
            ))}
          
       
          </TableBody>
          }

{selectedType==="Villa" &&  
<TableBody>
            {unitdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unit) => (
              <TableRow key={unit.id}>
                <TableCell>{unit.VillaNo}</TableCell>
                <TableCell>{unit.facing}</TableCell>
                <TableCell>{unit.villaplotsize}</TableCell>
                <TableCell>{unit.villasize}</TableCell>
                <TableCell>{unit.totalValue}</TableCell>
              </TableRow>
            ))}


       
          </TableBody>}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={unitsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>}

{tableStateA && (
  <div>
    {loading && <Typography variant="h6">Loading...</Typography>}
    {error && <Typography variant="h6" color="error">{error}</Typography>}
    <TableContainer component={Paper} style={{ marginTop: '40px' }}>
      <Table>
        <TableHead>
        <TableRow>
            {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa No</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Unit Name</TableCell>)}
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Facing</TableCell>
              {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa Size</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Cornor</TableCell>)}
                {selectedType==="Villa" ? (<TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Villa Sq.ft</TableCell>):(<TableCell align={"left"}
              style={{ top: 21, fontWeight: "600" }}>Unit Size</TableCell>)}
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Total Value</TableCell>
            </TableRow>
        </TableHead>
        {selectedType==="Apartment" &&
        <TableBody>
          {filteredAvailableUnitsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unit) => (
            <TableRow key={unit.id}>
              <TableCell>{unit.unit_name}</TableCell>
              <TableCell>{unit.Facing}</TableCell>
              <TableCell>{unit.cornor}</TableCell>
              <TableCell>{unit.unitSize}</TableCell>
              <TableCell style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }} onClick={() => handleClickTotalValue(unit)}>{unit.totalvalue}</TableCell>
            </TableRow>
          ))}
        </TableBody>}
        {selectedType==="Villa" &&
        <TableBody>
          {unitdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unit) => (
              <TableRow key={unit.id}>
                <TableCell>{unit.VillaNo}</TableCell>
                <TableCell>{unit.facing}</TableCell>
                <TableCell>{unit.villaplotsize}</TableCell>
                <TableCell>{unit.villasize}</TableCell>
                <TableCell>{unit.totalValue}</TableCell>
              </TableRow>
            ))}
        </TableBody>}
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={availableUnitsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  </div>
)}

{openActionPopup && (
  <ActionPopup
    open={openActionPopup}
    onClose={() => setOpenActionPopup(false)}
    onEditPricing={handleEditPricing}
    onApplyDiscount={() => setOpenDiscountModal(true)}
  />
)}

{openEditPricingModal && (
  <EditPricingModal
    open={openEditPricingModal}
    onClose={() => setOpenEditPricingModal(false)}
    unit={selectedUnit}
    onSave={handleSave}
  />
)}

{openDiscountModal && (
  <DiscountModal
    open={openDiscountModal}
    onClose={() => setOpenDiscountModal(false)}
    onApply={handleApplyDiscount}
  />
)}


    {tableStateB&&
            <div>
      {loading && <Typography variant="h6">Loading...</Typography>}
      {error && <Typography variant="h6" color="error">{error}</Typography>}
      <TableContainer component={Paper} style={{marginTop:'40px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Name</TableCell>
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Lead Date</TableCell>
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Executive</TableCell>
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Source</TableCell>
              <TableCell align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Status</TableCell>
        
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeadsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unit) => (
              <TableRow key={unit.id}>
                <TableCell>{unit.name}</TableCell>
                <TableCell>{unit.pretime}</TableCell>
                <TableCell>{unit.salesExecutive}</TableCell>
                <TableCell>{unit.sourceType}</TableCell>
                <TableCell>{determineStatus(unit)}</TableCell>
             
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={leadsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>}

   
  
    </main>





  )
}

export default DashBoardHomePage;


