import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography } from '@mui/material';
import { FaCheck, FaMobileAlt, FaRupeeSign, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import Createpurchasemaster from './Createpurchasemaster';



export default function PurchaseMaster() {

    const [customerRes, setCustomerResponse] = React.useState([])


    const customerObj = () => {
        axios.get('https://buildfastvapi.emedha.in/create-customer/api/v1/customers').then((res) => {
            setCustomerResponse(res.data)
        })
    }

    const  customerSearch = (text) => {
        axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/search', {
            text
        }).then((res) => {
            setCustomerResponse(res.data)
        })
    }

    const [data, setData] = React.useState([])

    const get_prom = () => {
        axios.get("https://buildfastvapi.emedha.in/pmaster/api/v1/get-prouom").then((res) => {
            if (res.data.status === 200) {
                setData(res.data.data)
            }
        })
    }
  
    React.useEffect(() => {
        get_prom()
       
    }, [])

    const [addPurchase, setAddPurchase] = React.useState(false)

    return (
        <div>


            <div className={!addPurchase ? `leadManagementTable`: ''}>
                {
                    !addPurchase && (
                        <Paper elevation={0} >
                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-3' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <input onChange={(e) => customerSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
                                    </div>
                                    <div>
                                        <Button onClick={() => setAddPurchase(true)} style={{ width: '12rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Purchase Master</Button>
                                    </div>
                                </div>

                                <Table style={{ position: 'relative', top: '1.3rem', overflow: 'scroll' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Product Names
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                UOM Names
                                            </TableCell>
 
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                       {
                                        data.map((row => (
                                            <TableRow hover role="checkbox" tabIndex={-1}  key={row.slno}>
                                                       
                                            <TableCell align={"start"}
                                                style={{ top: 57, fontSize: 12, fontWeight: 'bold',color:'grey' }}>{row.ProductName}</TableCell>
                                            <TableCell align={"start"}
                                                style={{ top: 57, fontSize: 12, fontWeight: 'bold',color:'grey' }}>{row.UOM}</TableCell>
                                            
                                        </TableRow>
                                        )))
                                       }
                                              
                                    </TableBody>
                                </Table>
                                {
                                    data.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 users</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>
                    )
                }
                {
                    addPurchase && (
                        <Createpurchasemaster/>
                    )
                }
            </div>
        </div>
    );
}