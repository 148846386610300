import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography, Menu, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaDotCircle } from 'react-icons/fa';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import Floors from './Floors';
// import UnitBlocks from './UnitBlocks';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import { FaEdit, FaSquarespace, FaTrash, FaUserCircle } from 'react-icons/fa';
// import Pagination from 'react-bootstrap/Pagination';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';


export default function Plotsvilla() {


    const [Blocks, setBlocks] = useState("")
    const [ProjectName, setProjectName] = useState("")
    const [Floor, setFloor] = useState('')
    const [selectType, setSelectType] = useState('')
    const [floorname, setFloorname] = useState()
    const [selectfloor, setSelectfloor] = useState([])
    const [selectunit, setSelectunit] = useState([])
    const [nofunits, setNofunits] = useState([])
    const [unitno, setUnitno] = useState()
    const [ft, setFt] = useState('')
    const [id, setId] = useState()
    const [corneru, setCorneru] = useState('')
    const [floorw, setFloorw] = useState('')
    const [facg, setFcg] = useState('')



    const unitData = (a, b, c, d) => {
        console.log(a);
        setNofunits(a)
        setCorneru(b)
        setFloorw(c)
        setFcg(d)
    }

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectName = searchParams.get('id');


    const add_blocks = () => {
        const floors = Array.prototype.map.call(floorname, function (item) { return item.attachment }).join(",");
        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-floor", {
            ProjectName, Blocks, Floor_Unit: Floor, selectType: floors, Corner: corneru, floorw, facg
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_floor()
                setBlocks("")
                setProjectName("")
                setSelectType('')
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [response, setResponse] = useState([])



    const get_floor = (val) => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-floor").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }

    const [twr, setTwr] = useState('')

    const select_floor = (value1, value2) => {
        console.log({ value1, value2 });
        axios.post("https://buildfastapi.emedha.in/project/api/v1/select-floor", {
            value1, value2
        }).then((res) => {
            setSelectfloor(res.data)
            console.log("flor", res.data);
            setTwr(value1)
            setBlocks(value2)
        })
    }

    const [responset, setResponset] = useState([])

    const get_tower = (value1) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-towers", {
            value1
        }).then((res) => {

            setResponset(res.data)
            console.log(res.data)
            setFt(value1)

        })
    }

    const [open1, setOpen1] = useState(false)

    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                blockEdit(id)
            }
        })
    }

    const[tyunt, setTyunt] = useState('')


    const blockEdit = (id) => {
        console.log("dd", {
            projectName: villaProjectName, villasize: eunitsize, facing: efac, mortgage: villaMortgage, villasqyard: villaSqyard, villaplotsize:villaPlotSize, id
        });
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/villa-unit-update', {
         projectName: villaProjectName, villasize: eunitsize, facing: efac, mortgage: villaMortgage, villasqyard: villaSqyard, villaplotsize:villaPlotSize, id
        }).then((res) => {
            if (res.data.status === 200) {
                get_unit()
                if (res.data.status === 200) {
                
                    get_unit()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }


    const [efac, setEfac] = useState('')
    const [eunitsize, setEunitsize] = useState('')
    const [villaSqyard,setunitsqyard]=useState('')
    const [villaPlotSize,setplotsize]=useState('')
    const [villaProjectName,setProjectNamee]=useState('')
    const [villaMortgage,setMortgage]=useState('')

    console.log(eunitsize)
    console.log(villaSqyard)
    console.log(villaPlotSize)
    console.log(villaProjectName)


    const viewData = (id) => {
        setOpen1(true)
        axios.post('https://buildfastvapi.emedha.in/project/api/v1/get-villa-units-id', { id })
        .then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                const data = res.data.data[0]; // Access the first object
                setEfac(data.facing);
             
                setEunitsize(data.villasize);
                setunitsqyard(data.villasqyard);
                setplotsize(data.villaplotsize);
                setProjectNamee(data.projectName);
                setMortgage(data.mortgage)
            } else {
                console.error("No data found");
            }
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
        });
    
    }

    const add_unit = () => {
        // const units = Array.prototype.map.call(nofunits, function (item) { return item.attachment }).join(",");
        console.log({
            projectName: ProjectName, UnitName: nofunits, block_name: twr, floor: Floor, unit_no: unitno, Towers: Blocks

        })
        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-units", {
            projectName: ProjectName, UnitName: nofunits, block_name: twr, floor: Floor, unit_no: unitno, Towers: Blocks
        })
            .then((res) => {
                console.log(res.data);

                if (res.data.status === 200) {
                    setOpen(false);
                    setBlocks("");
                    setProjectName("");
                    setSelectType('');

                    Swal.fire({
                        icon: 'success',
                        title: "Success!",
                        text: "Data added successfully!",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            get_unit();
                            return;
                        }
                    });
                }
            }).catch((error) => {
                console.error(error);
                setOpen(false);

                Swal.fire({
                    icon: 'error',
                    title: "Error!",
                    text: "Units already exist  in the selected block and floor. please try again.",
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "rgb(126, 110, 228)",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                });
            });
    }
    const [unitdata, setUnitdata] = useState([]);

    const get_unit = () => {
        axios.get("https://buildfastvapi.emedha.in/project/api/v1/get-villa-plots", {
            params: { projectName }
        })
        .then((res) => {
            setUnitdata(res.data.data);
            console.log("Response data:", res.data);
        })
        .catch((error) => {
            console.error("Error fetching units:", error);
        });
    };

    
    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    // const currentpost = unitdata.slice(indexOffpage, indexOflpage)




    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_floor()
        get_unit()
        get_tyunz(projectName)
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open2 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_unit()

        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/delete-unit', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_unit()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "unit is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const blockAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to Block ?",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataAlert(id)
            }
        })
    }

    const basicDataAlert = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/update-flag', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_unit()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "unit is Blocked successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }


    const searchCommon = (text) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/unit-search', {
            text
        }).then((res) => {

            setUnitdata(res.data)

        })
    }

    const [response2, setResponse2] = useState([])

    const get_block = (value) => {
        setProjectName('')
        setBlocks('')

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-blocks", {
            value
        }).then((res) => {

            setResponse2(res.data)
            setProjectName(value)
            res.data.forEach(data => {
                console.log(data.Blocks);
            });
            console.log(res.data)

        })
    }

    const [blog, setBlog] = useState('');

    const handlefloor = (e) => {
        const newBlocks = parseInt(e.target.value);
        if (!isNaN(newBlocks) && newBlocks !== '' && Number(newBlocks) >= 0 && Number(newBlocks) <= 99) {
            setBlog(newBlocks)
        }
        else {
            setBlog('')
        }
    };

    const renderInputs = () => {
        const input = parseInt(blog)
        return Array.from({ length: input }, (_, index) => (
            <>
                <Typography style={{ color: 'gray', fontSize: 13 }}></Typography>
                <Form.Control
                    key={index}
                    required
                    type="text"
                    style={{ padding: '0.42rem', marginBottom: 14 }}
                />
            </>
        ))
    };


    const [typicaluzdrop, setTypicaluzdrop] = useState([])

    const get_tyunz = (text) => {
        axios.post("https://buildfastapi.emedha.in/project/api/v1/gettypical-unitsize",{text}).then((res) => {
            if (res.data.status === 200) {
                setTypicaluzdrop(res.data.data)
                console.log(res.data.data);

            }
        })
    }


    return (
        <div>


            <div className='leadManagementTable'>
                <ArrowBackIcon onClick={()=>history.back()}/>
            <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Villa Plots </Typography>

                <Paper elevation={0} >

                    <TableContainer  >

                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, color: 'gray', }}>
                            {/* <div>
                                <label>
                                    Show
                                    <select onChange={(e) => setPage(e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                    </select>
                                    Entries
                                </label>
                            </div> */}
                        </Typography>

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >

                            <div>

                                <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
                            </div>
                            <div>
                                {/* <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Units</Button> */}
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa No
                                    </TableCell>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa Size
                                    </TableCell>
                                  
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa Sqyard
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa PlotSize
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa Facing
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Villa Mortgage
                                    </TableCell>
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {unitdata
                                    .map((row) => {

                                        if (row.lead === 'Not Customer') {
                                            color = 'red'
                                            widths = false
                                        }

                                        if (row.lead === 'Customer') {
                                            color = 'green'
                                            widths = true
                                        }



                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align={"start"}
                                                    style={{ top: 57 }}>
                                                    <div style={{ border: `3px solid rgb(126, 110, 228)`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%' }}>
                                                      {row.VillaNo}
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.ProjectName}</TableCell> */}
                  
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid ', backgroundColor: `${row.flag === 0 ? "green" : row.flag === 1 ? "red" : "grey"}`, borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaDotCircle style={{ marginRight: 3, color: 'white', marginTop: '-0.05rem', fontSize: 9 }} />{row.villasize}</button>
                                                </TableCell>

                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                  {row.villasqyard}
                                                </TableCell>


                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                 {row.villaplotsize}
                                                </TableCell>
                                                
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                 {row.facing}
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                 {row.mortgage===1?"Yes":"No"}
                                                </TableCell>

                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                                                        {/* <Button onClick={() => deleteAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button> */}
                                                        {row.flag === 0 && (
                                                            <Button onClick={() => blockAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }}>Mortgage</Button>
                                                        )}

                                                        {row.flag === 1 && (
                                                            <Button onClick={() => blockAlert(row.id)} style={{ backgroundColor: 'green', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }}>Release</Button>
                                                        )}
                                                        <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.slno)
                                                                    console.log(row.slno)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >

                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                    viewData(id)
                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>

                                                                <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>




                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {
                            unitdata.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>0 Customers</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
                <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                    <Pagination count={Math.ceil(unitdata.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                </div>

            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "28rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Units</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Adding unit details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_block(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {
                                        ProjectName !== "" && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => {
                                                        get_tower(e.target.value)
                                                    }}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select block </option>


                                                    {
                                                        response2.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.Blocks.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex}>{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>
                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )


                                    }

                                    {
                                        ft !== '' && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => {
                                                        select_floor(e.target.value, ft)
                                                    }}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Towers </option>


                                                    {
                                                        responset.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.Towers.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex}>{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>
                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }

                                    {
                                        Blocks !== "" && (

                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => setFloor(e.target.value)}
                                                    type="text"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Floor Name</option>



                                                    {
                                                        selectfloor.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.selectType.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex} >{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>

                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            // <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            //     <Typography style={{ color: 'gray', fontSize: 13 }}>No Of Floors</Typography>
                                            //     <Form.Control
                                            //         required
                                            //         type="number"
                                            //         style={{ padding: '0.42rem', marginBottom: 14, }}
                                            //     /> 
                                            //     <Form.Control.Feedback type="invalid">
                                            //         Please Enter Valid Floors
                                            //     </Form.Control.Feedback>
                                            //    <Floors floorData={floorData}/>
                                            // </Form.Group>




                                            // <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            //     {
                                            //         selectType === '' && (
                                            //             <FormControl>
                                            //                 <FormLabel id="demo-radio-buttons-group-label">Select Type</FormLabel>
                                            //                 <RadioGroup
                                            //                     aria-labelledby="demo-radio-buttons-group-label"
                                            //                     defaultValue="female"
                                            //                     name="radio-buttons-group"
                                            //                     className='d-flex'
                                            //                 >
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Floor" control={<Radio />} label="Floor" />
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Unit" control={<Radio />} label="Unit" />
                                            //                 </RadioGroup>
                                            //             </FormControl>
                                            //         )
                                            //     }
                                            //     <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType}</Typography>
                                            //     {
                                            //         selectType !== '' && (
                                            //             <Form.Control
                                            //                 required
                                            //                 onChange={handlefloor}

                                            //                 type= {`${selectType === 'Unit' ? 'text' : 'number'}`}

                                            //                 style={{ padding: '0.42rem', marginBottom: 14, }}

                                            //             />
                                            //         )
                                            //     }            
                                            //     <Form.Control.Feedback type="invalid">
                                            //         Please Enter Valid Blocks
                                            //     </Form.Control.Feedback>
                                            //     { renderInputs()}
                                            // </Form.Group>
                                        )
                                    }

                                    {
                                        Floor != '' && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>No Of Units</Typography>
                                                <Form.Control
                                                    onChange={(e) => setUnitno(e.target.value)}
                                                    required
                                                    type="number"
                                                    style={{ padding: '0.42rem', marginBottom: 14, }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Units
                                                </Form.Control.Feedback>
                                                <UnitBlocks unitData={unitData} />
                                            </Form.Group>

                                        )
                                    }
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen(false)
                                            setBlocks('')
                                            setProjectName('')
                                            setSelectType('')
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(!open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "28rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Units</Typography>
                                </div>
                                <Row className="mb-3">

<Form.Group as={Col} md="6" controlId="validationCustom01">
    <Typography style={{ color: 'gray', fontSize: 13 }}>Facing</Typography>
    <Form.Select
        required
        onChange={(e) => {
            setEfac(e.target.value)
            console.log(e.target.value)
        }}
        
        value={efac}
        style={{ padding: '0.42rem', marginBottom: 14 }}
    >
        <option>Select Facing Name</option>
        <option>East</option>
        <option>West</option>
        <option>North</option>
        <option>South</option>
    </Form.Select>
    <Form.Control.Feedback type="invalid">
        Please Enter project name
    </Form.Control.Feedback>
</Form.Group>


        <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Size</Typography>
            <Form.Control
                required
                onChange={(e) => {
                    setEunitsize(e.target.value)
                    console.log(e.target.value)
                }}

                type="text"
                value={eunitsize}
                style={{ padding: '0.42rem', marginBottom: 14, }}

            />
            <Form.Control.Feedback type="invalid">
                Please Enter Valid Blocks
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Sqyard</Typography>
            <Form.Control
                required
                onChange={(e) => {
                    setunitsqyard(e.target.value)
                    console.log(e.target.value)
                }}

                type="text"
                value={villaSqyard}
                style={{ padding: '0.42rem', marginBottom: 14, }}

            />
            <Form.Control.Feedback type="invalid">
                Please Enter Valid Blocks
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Typography style={{ color: 'gray', fontSize: 13 }}>Unit PlotSize</Typography>
            <Form.Control
                required
                onChange={(e) => {
                    setplotsize(e.target.value)
                    console.log(e.target.value)
                }}

                type="text"
                value={villaPlotSize}
                style={{ padding: '0.42rem', marginBottom: 14, }}

            />
            <Form.Control.Feedback type="invalid">
                Please Enter Valid Blocks
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId={`mortgage`}>
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Mortgage</Typography>
                                                            <Form.Select
                                                                required
                                                                value={villaMortgage}
                                                                onChange={(e) => setMortgage( e.target.value)}
                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            >
                                                                    <option>--Select Mortgage--</option>
                                                                    <option value={1}>Yes</option>
                                                                    <option value={0}>No</option>
                                                                    Pricing
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please Enter Valid Facing
                                                                </Form.Control.Feedback>
                                                            </Form.Group>


</Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen1(false)

                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

        </div>
    );
}